import Axios from "lib/Axios";
import { APIResponse, Meta } from "types/ApiResponse";
import { CategoriesType, OnBoardingCategory } from "types/categories";

// Get Category by ID
export const getCategories = (id) => Axios.get<APIResponse<CategoriesType[], Meta>>(`/v1/categories/${id}`);

// Get All Categories
export const getAllCategories = () => Axios.get<APIResponse<CategoriesType[], Meta>>(`/v1/categories`);

export const getOnBoardingCategories = () =>
  Axios.get<APIResponse<OnBoardingCategory, Meta>>(`/v1/onboarding-steps/categories`);

export const getOnBoardingCategoriesByID = (id: number) =>
  Axios.get<APIResponse<OnBoardingCategory, Meta>>(`/v1/onboarding-steps/categories/${id}`);

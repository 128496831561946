import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE } from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { getLandingSvgIconsColor } from "LocaleBased";
import PlusSvgImg from "public/svgs/images/landing-page/PlusSvgImg";
import { Item, Name, Number } from "./CountersSection.styled";

type Props = {
  number: string;
  name: string;
  active?: boolean;
};

export default function CounterItem({ number, name, active }: Props) {
  const { themeType } = useThemeType();
  const { localIpInfo } = useAuth();

  return (
    <Item>
      <Number
        active={active}
        isFoundingDay={Boolean(localIpInfo?.country_code === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME)}
      >
        {number.replace("+", "")}
        <PlusSvgImg color={getLandingSvgIconsColor(localIpInfo?.country_code, themeType)} />
      </Number>
      <Name>{name}</Name>
    </Item>
  );
}

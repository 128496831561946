import Axios from "lib/Axios";
import { APIResponse, Meta } from "types/ApiResponse";
import { Subjects } from "types/homepage";
import { Subject, SubjectCourses, SubjectSimilar } from "types/subject";

export const getSubject = (id: string | string[] | number, token?: string) =>
  Axios.get<APIResponse<Subjects, Meta>>(`/v1/subjects/${id}`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  });

export const getSubjectCourses = (id: string | string[] | number, token?: string) =>
  Axios.get<APIResponse<SubjectCourses[], Meta>>(`/v1/subjects/${id}/courses`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  });

export const getSubjectSimilar = (id: string | string[] | number, token?: string) =>
  Axios.get<APIResponse<SubjectSimilar[], Meta>>(`/v1/subjects/${id}/similar`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  });

// TODO: Add Type Subject Reviews
export const getSubjectReviews = (id: string | string[] | number, token?: string) =>
  Axios.get<APIResponse<any, Meta>>(`/v1/subjects/${id}/similar`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  });

export const _GetTopSubjects = (country?: string) =>
  Axios.get<APIResponse<Subject[], {}>>(`/v1/top-subjects?country=${country}`);

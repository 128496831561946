// @ts-nocheck
import Link from "@Components/common/Link";
import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE, SECTION_ONE, SECTION_THREE, SECTION_TWO } from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import theme from "@Style/theme";
import { getCommonSectionBtnArrowColor, getLandingSvgimgsColor } from "LocaleBased";
import Image from "next/image";
import NavArrowRightSvgIcon from "public/svgs/icons/global-icons/LongArrowRightSvgIcon";
import SectionOneSvgImg from "public/svgs/images/landing-page/SectionOneSvgImg";
import SectionThreeSvgImg from "public/svgs/images/landing-page/SectionThreeSvgImg";
import SectionTwoSvgImg from "public/svgs/images/landing-page/SectionTwoSvgImg";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { LandingBGColors } from "types/common";
import { ArrowDirectionWrapper } from "../HeroArea/HeroArea.styled";
import { CtaBtn, Desc, ImgWrapper, InfoSide, Inner, SectionIcon, SectionTitle, Wrapper } from "./CommonSection.styled";

interface Props {
  title: string | ReactNode;
  titleUnderlined?: string;
  desc: string;
  image: any;
  radius?: "top" | "bottom";
  cta: {
    text: string;
    action?: (() => void) | string;
    variant?: string;
  };
  direction?: "ltr" | "rtl";
  commonBG?: LandingBGColors;
  id?: string;
  icon?: string;
  titleClass?: string;
  arrowURL?: string;
  width?: number;
  height?: number;
}

export default function CommonSection({
  desc,
  cta,
  mediaURL,
  title,
  radius,
  titleUnderlined,
  direction,
  commonBG,
  id,
  icon,
  titleClass,
  arrowURL,
  width,
  height,
}: Props): ReactElement {
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [isFoundingDay, setIsFoundingDay] = useState<boolean>();
  const [landingSvgsColor, setLandingSvgsColor] = useState(theme.colors.darkSeagule);

  useEffect(() => {
    setLandingSvgsColor(getLandingSvgimgsColor(localIpInfo?.country_code, themeType));
    setIsFoundingDay(Boolean(localIpInfo?.country_code === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME));
  }, []);

  return (
    <Wrapper radius={radius} commonBG={commonBG} id={id}>
      <Container>
        <Row>
          <Col col={12}>
            <Inner direction={direction}>
              <InfoSide>
                <SectionIcon>
                  {icon === SECTION_ONE ? (
                    <SectionOneSvgImg color={landingSvgsColor} />
                  ) : icon === SECTION_TWO ? (
                    <SectionTwoSvgImg color={landingSvgsColor} />
                  ) : (
                    icon === SECTION_THREE && <SectionThreeSvgImg color={landingSvgsColor} />
                  )}
                </SectionIcon>
                <SectionTitle className={titleClass} isFoundingDay={isFoundingDay}>
                  {title} {titleUnderlined && <span className="underlined">{titleUnderlined}</span>}
                </SectionTitle>
                <Desc>{desc}</Desc>
                <CtaBtn variant={cta.variant || "default"} href={cta?.action || "/"} as={Link}>
                  {cta.text}
                  <ArrowDirectionWrapper>
                    <NavArrowRightSvgIcon
                      color={getCommonSectionBtnArrowColor(isFoundingDay, arrowURL)}
                      width="13"
                      height="11"
                    />
                  </ArrowDirectionWrapper>
                </CtaBtn>
              </InfoSide>
              <ImgWrapper>
                {mediaURL && mediaURL?.includes("png" || "jpg" || "svg") ? (
                  <Image width={width} height={height} src={mediaURL} loading="lazy" alt="" />
                ) : (
                  <ReactPlayer className={"video-player"} loop playing muted playsinline url={mediaURL} />
                )}
              </ImgWrapper>
            </Inner>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

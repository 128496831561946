const SectionTwoSvgImg = ({ color }: { color: string }) => {
  return (
    <svg width="140" height="90" viewBox="0 0 140 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_416_103536)">
        <path
          d="M30.5657 40.1173C29.3023 38.9598 27.234 38.6304 25.8651 39.6583C24.4202 40.7427 24.1893 42.795 24.0805 44.6079C23.7143 50.7783 23.3482 57.0152 24.4334 63.0991C24.7567 64.9087 25.6276 67.0774 27.4451 67.1939C28.4974 67.2604 29.4738 66.5319 30.0115 65.6172C30.5492 64.7024 30.7273 63.6247 30.8758 62.5702C31.4629 58.4522 32.9309 42.2861 30.5657 40.1207V40.1173Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M71.2913 18.4287C70.8691 16.5926 69.7475 14.8362 68.0454 14.0546C66.3433 13.2729 64.0606 13.7086 63.0446 15.292C62.4046 16.2932 62.3321 17.5439 62.2826 18.7347C62.1045 22.8594 61.9263 26.9874 61.7449 31.1121C61.6822 32.579 61.6196 34.0726 62.0187 35.4829C62.4178 36.8933 63.3547 38.2338 64.7302 38.7128C66.6534 39.3881 68.8371 38.1407 69.8696 36.3711C70.9021 34.6015 71.0439 32.456 71.0472 30.4003C71.0472 28.3446 71.7136 20.2649 71.2913 18.4287V18.4287Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M38.9641 20.2819C32.0995 20.1056 25.2383 19.7629 18.3704 19.8228C16.6221 19.4902 16.0052 21.7188 17.7964 21.9916C24.1233 21.7987 35.9062 22.1779 38.8718 22.6635C40.752 23.0261 40.8807 20.2187 38.9641 20.2819Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M40.3498 28.7004C40.0166 28.115 39.314 28.1216 38.7268 28.0252C31.7567 27.1404 24.7008 27.4264 17.691 27.4331C16.1076 27.4364 16.0944 29.8813 17.691 29.8913C24.5655 29.9312 34.808 29.8247 39.5449 30.3503C40.3036 30.3037 40.7489 29.3358 40.3498 28.6971V28.7004Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M53.462 53.6058C49.1835 47.7514 45.5682 41.408 40.8246 35.9095C40.2111 34.875 38.7234 35.9029 39.5349 36.8176C43.6384 41.5211 47.0691 47.7514 51.0572 53.3297C50.3315 53.2831 49.4903 53.2665 48.6459 53.2532C48.5073 53.0802 48.382 52.9771 48.1214 52.5846C46.1652 50.1264 43.5889 45.9418 41.3128 42.8383C40.9533 42.4492 40.7257 41.8737 40.2573 41.6176C39.6272 41.3049 38.9378 42.1032 39.3138 42.6986C41.6097 45.6924 43.4735 48.9921 45.6803 52.049C45.9574 52.4415 46.2609 52.8141 46.5479 53.1999C46.3731 53.1933 46.1949 53.1866 46.03 53.1767C45.5418 53.17 45.0536 53.1334 44.5621 53.1101C43.2393 51.274 41.2238 48.4233 39.967 46.158C39.5118 45.3464 38.255 46.0849 38.7333 46.8865C39.8878 49.0087 41.2469 51.0212 42.6356 53.0004C41.8769 52.9405 41.1182 52.8773 40.3628 52.8008C39.9868 52.7309 39.5777 52.7841 39.3039 53.0769C38.6541 53.6889 39.1423 54.8897 40.0363 54.8598C44.1266 55.2756 48.2599 55.3721 52.3635 55.3587C52.6406 55.3155 52.8517 55.1425 52.9869 54.923C53.462 54.7201 53.7918 54.128 53.462 53.6058V53.6058Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M52.3733 55.3622H52.3928C52.445 55.3556 52.4157 55.3589 52.3733 55.3622Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M52.324 55.365C52.3435 55.365 52.3566 55.3617 52.3729 55.3584H52.3631C52.3631 55.3584 52.337 55.3617 52.324 55.365Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M52.324 55.3652C52.2679 55.3719 52.2052 55.3819 52.1953 55.3852C52.2415 55.3852 52.2778 55.3719 52.324 55.3652Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M52.469 61.2793H52.4591C52.4261 61.2859 52.3931 61.2893 52.3601 61.2959C52.3832 61.2926 52.4261 61.2859 52.469 61.2793Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M51.1495 59.0641C49.421 59.0574 47.6892 59.094 45.9606 59.0807C43.7835 59.074 41.6097 58.9909 39.4325 58.9809C39.0004 58.9576 38.5254 58.9609 38.212 59.3069C37.5127 59.9655 38.0372 61.2561 39.0004 61.2262C43.4866 61.4391 47.9827 61.7817 52.4624 61.2794C52.5382 61.2694 52.6141 61.2595 52.69 61.2462C52.6471 61.2628 52.9143 61.2462 53.0627 61.1563C53.3233 61.0665 53.5476 60.8403 53.6367 60.5776C54.0161 58.6948 52.2908 59.0641 51.1528 59.0641H51.1495Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M52.469 61.279C52.5086 61.2757 52.5482 61.2723 52.5877 61.269C52.6999 61.249 52.5779 61.2657 52.469 61.279Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M71.2683 55.5883C71.1363 55.3754 70.6646 55.249 70.47 55.2091C70.5162 55.219 70.5789 55.229 70.6745 55.2423C70.5756 55.2257 70.503 55.2124 70.4436 55.2024C70.404 55.1924 70.3842 55.1924 70.3908 55.1924C70.1302 55.1459 70.1236 55.1525 69.2231 55.0926C66.4951 54.9496 63.7604 54.9163 61.0291 54.943C60.5673 54.913 60.0758 54.9962 59.7459 55.3521C58.8817 56.1671 59.5315 57.7571 60.719 57.7171C63.8264 57.6706 66.9272 57.8901 70.0346 57.9134C70.117 57.9134 70.2325 57.9001 70.3413 57.8868H70.3249C70.5096 57.8968 70.6811 57.8568 70.8395 57.7737C71.7103 57.4743 71.928 56.2103 71.2683 55.5883V55.5883Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M72.324 63.4809C72.2614 63.2481 72.1228 63.0485 71.948 62.8855C71.9579 62.8889 71.9678 62.8922 71.9777 62.8988C71.9612 62.8822 71.9381 62.8755 71.9216 62.8589C71.8556 62.799 71.7863 62.7525 71.7105 62.7092C71.2618 62.4265 70.7077 62.3832 70.1799 62.3832C67.8939 62.33 65.6079 62.4065 63.3285 62.5429C62.2498 62.7358 60.9138 62.32 60.0232 63.0751C59.1325 63.9167 59.8022 65.5599 61.026 65.52C64.1499 65.3736 67.2704 65.1475 70.3976 65.2439C70.7934 65.2639 71.1926 65.2173 71.5521 65.051C71.5917 65.0344 71.6313 65.021 71.6676 64.9978C71.6742 64.9944 71.6808 64.9911 71.6874 64.9878C71.7731 64.9312 71.8292 64.8913 71.8622 64.8647C72.2548 64.5421 72.4659 63.9999 72.3207 63.4776L72.324 63.4809Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M109.632 29.5127C107.29 29.2665 104.931 29.3297 102.583 29.4262C100.801 29.4861 99.0169 29.5027 97.2356 29.6125C96.9684 29.6125 96.7078 29.7223 96.5198 29.9119C95.8831 30.5106 96.3614 31.6848 97.2356 31.6549C100.561 31.6948 103.886 31.6116 107.211 31.6682C108.105 31.6782 108.995 31.7114 109.889 31.7014C110.308 31.7114 110.75 31.452 110.889 31.0428C111.318 29.8021 110.031 29.4861 109.635 29.5127H109.632Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M111.169 35.4098C110.981 35.0106 105.687 35.2601 103.292 35.2335C101.135 35.28 98.9774 35.3832 96.8267 35.5628C96.5199 35.5628 96.2197 35.6892 96.002 35.9054C95.2697 36.594 95.8206 37.9445 96.8267 37.9112C101.422 37.5819 106.053 37.9245 110.638 37.5752C110.619 37.5819 110.599 37.5852 110.586 37.5919C110.635 37.5919 110.675 37.5752 110.721 37.5719C110.764 37.5686 110.81 37.5686 110.853 37.5653C110.876 37.5586 110.882 37.5519 110.902 37.5453C111.806 37.2991 112.08 35.9154 111.173 35.4098H111.169Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M103.055 76.6935C100.756 74.6911 98.6345 72.2562 96.0153 70.726C95.7415 70.6728 95.5172 70.7793 95.3588 70.9522C96.3517 52.8702 96.7146 34.7548 96.4441 16.6461C91.473 16.5464 86.5018 16.4466 80.805 16.2969C78.9181 36.8804 78.0737 57.557 78.2716 78.227C83.8233 78.2336 89.375 78.2403 94.9267 78.2469C95.0587 76.1779 95.1774 74.1056 95.2962 72.0333C95.3523 72.0998 95.4215 72.1597 95.5073 72.2096C97.117 73.3938 99.7692 75.9085 101.732 77.7347C102.204 78.1006 102.675 78.7925 103.342 78.6428C104.45 78.1771 103.714 77.1991 103.058 76.6935H103.055Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M91.4926 23.8408C91.4464 26.6116 91.4926 29.3825 91.4728 32.15C91.4102 33.0149 91.4794 34.1525 92.6472 33.9296C93.7225 33.5438 93.2574 32.0768 93.4455 31.1854C93.5939 28.8303 93.706 26.4719 93.7192 24.1102C93.9831 22.4736 91.5784 22.1776 91.4926 23.8374V23.8408Z"
          fill="#FEC76C"
        />
        <path
          d="M91.0969 37.8881C90.8033 39.9238 93.8381 40.2032 93.9107 38.1575C94.2043 36.1218 91.1695 35.8424 91.0969 37.8881Z"
          fill="#FEC76C"
        />
        <path
          d="M110.077 76.737C105.997 72.7653 101.692 69.0431 97.3807 65.3309C96.6022 64.5226 95.3487 65.7733 96.1536 66.5683C100.455 70.1907 105.515 74.9707 109.2 78.1906C110.061 78.6031 110.81 77.3457 110.077 76.7403V76.737Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M102.438 63.654C102.045 62.9022 101.204 62.4532 100.647 61.8145C99.7593 61.0361 99.0039 60.0582 98.0736 59.353C97.2654 58.9538 96.4969 60.1181 97.15 60.7334C98.4926 62.074 99.8219 63.4943 101.343 64.6153C101.933 64.9114 102.626 64.276 102.435 63.6507L102.438 63.654Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M110.75 71.548C110.79 71.5979 110.846 71.6611 110.714 71.4948C109.744 70.5035 108.679 69.5988 107.692 68.6208C106.927 67.879 106.205 67.0974 105.492 66.3024C105.327 66.136 105.096 66.0396 104.862 66.0396C104.097 66.013 103.675 67.0408 104.232 67.5697C105.835 69.3726 107.63 70.9859 109.431 72.5825C109.731 72.8719 110.186 73.0449 110.569 72.812C111.011 72.5659 111.097 71.9106 110.747 71.5447L110.75 71.548Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M1.14464 89.4998C0.827965 89.4998 0.521186 89.3668 0.306771 89.1306C0.0890566 88.8944 -0.0198004 88.5784 0.00329047 88.2591L0.649835 79.3777C0.692718 78.779 1.18422 78.3133 1.78129 78.3066L138.845 76.9461C139.198 76.9262 139.472 77.0725 139.69 77.3087C139.908 77.5416 140.023 77.8576 140 78.1769L139.337 88.2757C139.297 88.8811 138.799 89.3535 138.196 89.3535L1.14794 89.4965L1.14464 89.4998ZM2.85336 80.6085L2.37505 87.188L137.117 87.0483L137.628 79.2713L2.85666 80.6085H2.85336Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M14.5473 79.747C14.5473 79.747 14.5176 79.747 14.5011 79.747C13.8677 79.7237 13.3762 79.1848 13.4026 78.5462L16.0845 10.8415C16.1405 9.44777 16.2065 7.71473 17.1368 6.25114C18.8026 3.61999 22.131 3.26074 25.1724 3.26074H25.1889L36.1504 3.26739C37.427 3.26739 39.1753 3.26739 40.5509 4.33516C42.6951 6.00166 42.5136 9.15505 42.4246 10.6685L38.5288 78.1537C38.4925 78.7923 37.9449 79.2713 37.3182 79.2414C36.6848 79.2048 36.2032 78.6593 36.2395 78.0206L40.1386 10.5355C40.2408 8.7459 40.1946 6.97628 39.1523 6.16465C38.4529 5.62246 37.3776 5.57589 36.1471 5.57589L25.1922 5.56923C22.7643 5.51601 20.1386 5.79543 19.0632 7.49187C18.4694 8.4299 18.4166 9.76044 18.3705 10.9346L15.6919 78.6393C15.6688 79.2613 15.1608 79.747 14.5473 79.747V79.747Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M53.848 14.3014C53.7853 14.3014 53.7194 14.2947 53.6567 14.2847C49.718 13.6128 45.72 13.5662 41.7682 14.1517C41.1282 14.2548 40.5609 13.8091 40.4685 13.1771C40.3761 12.545 40.8083 11.9596 41.435 11.8665C45.6244 11.2444 49.8665 11.2943 54.0393 12.0062C54.6628 12.1126 55.0817 12.708 54.9762 13.3367C54.8805 13.9022 54.3956 14.298 53.848 14.298V14.3014Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M78.1561 79.2445C78.1561 79.2445 78.1265 79.2445 78.1133 79.2445C77.4799 79.2212 76.9884 78.6856 77.0115 78.047C77.8131 56.1529 78.6411 33.5137 79.1689 11.2371C79.2216 8.96851 79.182 6.9727 78.1529 5.52241C76.6684 3.43345 73.5347 3.09416 70.711 2.94115C68.1479 2.80145 65.5024 2.65509 63.0283 3.22057C60.4224 3.81598 58.3343 5.21305 57.3018 7.05586C56.2792 8.8787 56.1934 11.2238 56.1143 13.2961L53.7095 77.6678C53.6864 78.3064 53.1982 78.8154 52.522 78.7788C51.8886 78.7555 51.3971 78.2199 51.4202 77.5813L53.825 13.2096C53.9074 10.9909 54.013 8.23006 55.3094 5.92157C56.6585 3.51329 59.2876 1.7104 62.5236 0.968623C65.311 0.333288 68.1215 0.486301 70.8363 0.635987C73.2576 0.769041 77.7669 1.01519 80.0166 4.17856C81.5769 6.37728 81.5109 9.21799 81.4614 11.2936C80.9304 33.5836 80.1024 56.2327 79.3008 78.1335C79.2777 78.7555 78.7697 79.2445 78.1561 79.2445V79.2445Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M94.7782 79.261H94.7419C94.1086 79.2411 93.6138 78.7088 93.6303 78.0735L95.3951 18.1725C90.5097 17.587 85.555 17.4939 80.6466 17.903C80.0033 17.9396 79.4624 17.4839 79.4096 16.8453C79.3568 16.2099 79.8252 15.6511 80.4586 15.5979C85.8684 15.1488 91.3443 15.2885 96.7211 16.0137C97.305 16.0902 97.7338 16.6024 97.714 17.1912L95.9196 78.14C95.8998 78.7654 95.3918 79.261 94.7749 79.261H94.7782Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M110.318 79.0118H110.299C109.665 79.0018 109.161 78.473 109.174 77.8376L110.157 22.008C105.954 21.4658 101.597 21.7286 97.4931 22.7764C96.8828 22.9261 96.256 22.5602 96.101 21.9415C95.946 21.3228 96.3154 20.6941 96.929 20.5378C101.649 19.3303 106.687 19.0975 111.499 19.8692C112.063 19.959 112.476 20.4546 112.466 21.0301L111.466 77.8775C111.456 78.5095 110.945 79.0118 110.322 79.0118H110.318Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M121.056 78.5231H121.046C120.412 78.5164 119.904 77.9942 119.911 77.3555L120.574 5.90871L112.875 5.80559L112.195 20.8441C112.166 21.4827 111.628 21.9783 110.998 21.9451C110.365 21.9151 109.876 21.3763 109.906 20.7376L110.635 4.58149C110.661 3.96612 111.166 3.48047 111.78 3.48047H111.793L121.742 3.61352C122.372 3.6235 122.88 4.14241 122.873 4.78107L122.197 77.3788C122.19 78.0141 121.679 78.5231 121.052 78.5231H121.056Z"
          fill={color ? color : "#033447"}
        />
      </g>
      <defs>
        <clipPath id="clip0_416_103536">
          <rect width="140" height="89" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SectionTwoSvgImg;

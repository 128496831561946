import { getStatisticsCount } from "@Services/Home";
import useTranslation from "next-translate/useTranslation";
import { useQuery } from "react-query";
import { Col, Container, Row } from "styled-bootstrap-grid";
import CounterItem from "./CounterItem";
import { Wrapper } from "./CountersSection.styled";
type Props = {};

export default function CountersSection({}: Props) {
  const { t } = useTranslation("common");
  const { t: tLanding } = useTranslation("Landing");

  // Statistics Count
  const { data: statisticsCount, isLoading } = useQuery(["statistics-count"], () => getStatisticsCount());

  return (
    <Wrapper>
      <Container>
        {statisticsCount && (
          <Row>
            <Col col={6} lg={3} md={3} sm={6}>
              <CounterItem
                number={statisticsCount.subjects_and_courses_count}
                name={`${tLanding("courses_subjects")}`}
              />
            </Col>
            <Col col={6} lg={3} md={3} sm={6}>
              <CounterItem number={statisticsCount.instructors_count} name={tLanding("teachers")} />
            </Col>
            <Col col={6} lg={3} md={3} sm={6}>
              <CounterItem number={statisticsCount.students_count} name={tLanding("students")} active />
            </Col>
            <Col col={6} lg={3} md={3} sm={6}>
              <CounterItem number={statisticsCount.total_views} name={tLanding("lecture_views")} />
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  );
}

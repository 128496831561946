import { mq, mw } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 60px 0 30px 0;
  ${mq(576)} {
    padding: 36px 0 0 0;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  text-align: center;
  ${mq(576)} {
    margin-bottom: 36px;
    &:last-of-type {
    }
  }
`;

export const Number = styled.h3<{
  readonly active?: boolean;
  readonly isFoundingDay?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 4rem;
  line-height: 64px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkSeagule};
  svg {
    margin-left: 10px;
  }
  ${mq(992)} {
    font-weight: 800;
    font-size: 20px;
    line-height: 44px;
  }
  ${mw(1920)} {
    font-size: calc(4rem * 1.2);
  }
  ${mq(576)} {
    font-weight: 600;
    font-size: 28px;
    line-height: 56px;
  }
  ${({ active, isFoundingDay }) =>
    active &&
    css`
      position: relative;
      &::after {
        position: absolute;
        ${isFoundingDay
          ? css`
              content: url("/images/landing-common-sections/ksaFounding/counter_underline_brown.svg");
            `
          : css`
              content: url("/images/landing-common-sections/counter_underline.svg");
            `}
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    `}
`;

export const Name = styled.p`
  font-size: 2rem;
  line-height: 36px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkSeagule};
  opacity: 0.6;
  ${mq(992)} {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
  ${mw(1920)} {
    font-size: calc(2rem * 1.2);
  }
`;

import Link from "@Components/common/Link";
import SubjectCardContent from "@Components/SubjectCardContent";
import { useAuth } from "@Context/Auth.context";
import { _GetTopSubjects } from "@Services/Subject";
import { getLocalizedHomeSubRoute } from "@Utils/Strings";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { ReactElement, useEffect, useState } from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import SwiperCore, { Grid, Navigation } from "swiper";
// import "swiper/css/bundle";
import LightArrowLeftSvgIcon from "public/svgs/icons/global-icons/LightArrowLeftSvgIcon";
import NavArrowRightSvgIcon from "public/svgs/icons/global-icons/LongArrowRightSvgIcon";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { LandingBGColors } from "types/common";
import { Subject } from "types/subject";
import CountersSection from "../CountersSection";
import { ArrowDirectionWrapper } from "../HeroArea/HeroArea.styled";
import {
  ArrowBtn,
  ArrowsWrapper,
  Inner,
  OuterWrapper,
  SectionTitle,
  ShowMoreBtn,
  SliderWrapper,
  SubjectWrapper,
  Wrapper,
} from "./CoursesSection.styled";

interface Props {
  commonBG?: LandingBGColors;
}

export default function CoursesSection({ commonBG }: Props): ReactElement {
  const router = useRouter();
  const { localIpInfo } = useAuth();
  const [topCourses, setTopCourses] = useState<Subject[]>();
  const { t: tLanding } = useTranslation("Landing");
  const swiperRef = React.useRef<SwiperCore>();
  const [subRoute, setSubroute] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setSubroute(getLocalizedHomeSubRoute() || "");
    }
    // TODO: Needs to be tested (LOCALE_IP_BASED_TEST)
    _GetTopSubjects(localIpInfo?.country === "saudi arabia" ? "saudi" : localIpInfo?.country_code).then(({ data }) => {
      setTopCourses(data.data);
    });
  }, []);

  return (
    <OuterWrapper commonBG={commonBG}>
      <Wrapper>
        <Container>
          <Row>
            <Col col={12}>
              <Inner>
                <SectionTitle>
                  {tLanding("explore")} {tLanding("popular_courses")}
                </SectionTitle>
                <ArrowsWrapper>
                  <ArrowBtn variant="default" className="courses-next">
                    <LightArrowLeftSvgIcon color="white" width="24" height="24" />
                  </ArrowBtn>
                  <ArrowBtn variant="default" className="inverse-dir courses-prev">
                    <LightArrowLeftSvgIcon color="white" width="24" height="24" />
                  </ArrowBtn>
                </ArrowsWrapper>
              </Inner>
            </Col>
          </Row>
        </Container>
        <SliderWrapper>
          <Swiper
            slidesPerView={"auto"}
            grid={{
              fill: "row",
              rows: 2,
            }}
            loop={false}
            spaceBetween={12}
            modules={[Grid, Navigation]}
            navigation={{
              prevEl: ".courses-next",
              nextEl: ".courses-prev",
            }}
            breakpoints={{
              992: {
                spaceBetween: 28,
                grid: {
                  fill: "row",
                  rows: 2,
                },
              },
            }}
          >
            {topCourses
              ? topCourses.map((course) => (
                  <SwiperSlide key={course.id + course.name}>
                    <SubjectWrapper>
                      <SubjectCardContent packageLayout data={course} />
                    </SubjectWrapper>
                  </SwiperSlide>
                ))
              : [...new Array(10)].map((_, i) => (
                  <SwiperSlide key={i + "DUMMY_COURSE_CARD"}>
                    <SubjectWrapper>
                      <SubjectCardContent />
                    </SubjectWrapper>
                  </SwiperSlide>
                ))}
          </Swiper>
        </SliderWrapper>
        <Container>
          <Row justifyContent="center">
            <Col auto>
              <ShowMoreBtn variant="default" href={`/home/${subRoute}`} as={Link}>
                {tLanding("explore_courses")}
                <ArrowDirectionWrapper>
                  <NavArrowRightSvgIcon width="13" height="11" />
                </ArrowDirectionWrapper>
              </ShowMoreBtn>
            </Col>
          </Row>
        </Container>
        <CountersSection />
      </Wrapper>
    </OuterWrapper>
  );
}

type Props = {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
};

const InformationSvgIcon = ({ color, width, height, margin }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0" }}
      width={width ? width : "16"}
      height={height ? height : "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C9.09281 16 10.1203 15.7909 11.0824 15.3726C12.0497 14.9542 12.902 14.3765 13.6392 13.6392C14.3765 12.902 14.9542 12.0523 15.3726 11.0902C15.7909 10.1229 16 9.09281 16 8C16 6.90719 15.7909 5.87974 15.3726 4.91765C14.9542 3.95033 14.3765 3.09804 13.6392 2.36078C12.902 1.62353 12.0497 1.04575 11.0824 0.627451C10.115 0.20915 9.08497 0 7.99216 0C6.89935 0 5.86928 0.20915 4.90196 0.627451C3.93987 1.04575 3.0902 1.62353 2.35294 2.36078C1.62092 3.09804 1.04575 3.95033 0.627451 4.91765C0.20915 5.87974 0 6.90719 0 8C0 9.09281 0.20915 10.1229 0.627451 11.0902C1.04575 12.0523 1.62353 12.902 2.36078 13.6392C3.09804 14.3765 3.94771 14.9542 4.9098 15.3726C5.87712 15.7909 6.90719 16 8 16ZM6.58824 12.4549C6.42092 12.4549 6.27974 12.4026 6.16471 12.298C6.04967 12.1882 5.99216 12.0497 5.99216 11.8824C5.99216 11.7255 6.04967 11.5922 6.16471 11.4824C6.27974 11.3673 6.42092 11.3098 6.58824 11.3098H7.58431V7.61569H6.72157C6.55948 7.61569 6.42092 7.5634 6.30588 7.45882C6.19085 7.34902 6.13333 7.21046 6.13333 7.04314C6.13333 6.88627 6.19085 6.75294 6.30588 6.64314C6.42092 6.5281 6.55948 6.47059 6.72157 6.47059H8.24314C8.45229 6.47059 8.60915 6.53856 8.71373 6.67451C8.82353 6.81046 8.87843 6.98824 8.87843 7.20784V11.3098H9.87451C10.0418 11.3098 10.183 11.3673 10.298 11.4824C10.4131 11.5922 10.4706 11.7255 10.4706 11.8824C10.4706 12.0497 10.4131 12.1882 10.298 12.298C10.183 12.4026 10.0418 12.4549 9.87451 12.4549H6.58824ZM7.92941 5.12941C7.6366 5.12941 7.38562 5.02745 7.17647 4.82353C6.97255 4.61438 6.87059 4.3634 6.87059 4.07059C6.87059 3.77255 6.97255 3.52157 7.17647 3.31765C7.38562 3.1085 7.6366 3.00392 7.92941 3.00392C8.22745 3.00392 8.47582 3.1085 8.67451 3.31765C8.87843 3.52157 8.98039 3.77255 8.98039 4.07059C8.98039 4.3634 8.87843 4.61438 8.67451 4.82353C8.47582 5.02745 8.22745 5.12941 7.92941 5.12941Z"
        fill={color ? color : "#C4C4C4"}
      />
    </svg>
  );
};

export default InformationSvgIcon;

import Link from "@Components/common/Link";
import { APPLE_APPSTORE_LINK, GOOGLE_PLAY_LINK } from "@Constants/AppStoreLinks";
import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE } from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { Container } from "@material-ui/core";
import { AppsIconBgColor } from "LocaleBased";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";
import AppStoreSvgImg from "public/svgs/images/social-apps/AppStoreSvgImg";
import GooglePlaySvgImg from "public/svgs/images/social-apps/GooglePlaySvgImg";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { LandingBGColors } from "types/common";
import { SectionTitle } from "../GetStartedSection/GetStartedSection.styled";
import {
  PlatformFooter,
  PlatformHeader,
  PlatformHeaderParagraph,
  PlatformImageContainer,
  PlatformWrapper,
} from "./PlatformsSection.styled";

interface Props {
  commonBG?: LandingBGColors;
}

export default function PlatformsSection({ commonBG }: Props): ReactElement {
  const { locale } = useRouter();
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [isFoundingDay, setIsFoundingDay] = useState<boolean>();
  const isMobile = useMediaQuery({
    maxWidth: 700,
  });
  const isTablet = useMediaQuery({
    minWidth: 701,
    maxWidth: 991,
  });
  const myRef = useRef(null);
  const [startScrollElem, setStartScroll] = React.useState(0);
  const { t: tLanding } = useTranslation("Landing");

  useEffect(() => {
    // TODO: We need smarter way to calulate wheel
    window.addEventListener("wheel", () => {
      if (myRef.current instanceof HTMLElement) {
        setStartScroll(myRef.current.getBoundingClientRect().top + window.scrollY);
      }
    });
    setIsFoundingDay(Boolean(localIpInfo?.country_code === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME));
  }, []);

  return (
    <PlatformWrapper ref={myRef} commonBG={commonBG}>
      <Container style={{ height: "100%", position: "relative" }}>
        <PlatformHeader>
          <SectionTitle isFoundingDay={isFoundingDay}>
            <Trans
              i18nKey="Landing:landing_devices_title"
              components={{
                p: <p className="circle" />,
              }}
            />
          </SectionTitle>
          <PlatformHeaderParagraph>{tLanding("landing_devices_body")}</PlatformHeaderParagraph>
        </PlatformHeader>
        <PlatformImageContainer>
          <Image
            width={isMobile ? 902 : isTablet ? 1802 : 2702}
            height={isMobile ? 363 : isTablet ? 726 : 1089}
            src={`/images/platform/sec_image/${locale}/${isMobile ? "1x" : isTablet ? "2x" : "3x"}.png`}
            loading="lazy"
            alt="Platform image"
          />
        </PlatformImageContainer>
        <PlatformFooter>
          <Link href={APPLE_APPSTORE_LINK}>
            <AppStoreSvgImg bgColor={AppsIconBgColor(isFoundingDay)} />
          </Link>
          <Link href={GOOGLE_PLAY_LINK}>
            <GooglePlaySvgImg bgColor={AppsIconBgColor(isFoundingDay)} />
          </Link>
        </PlatformFooter>
      </Container>
    </PlatformWrapper>
  );
}

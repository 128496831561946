// @ts-nocheck
import { CommonButton } from "@Components/common/CommonButton";
import {
  COLLEGE_OR_GRADE,
  COLLEGE_TYPE,
  COUNTRY,
  COUNTRY_TYPE,
  DEPARTMENT_TYPE,
  GRADE_TYPE,
  LANDING_CTA,
  STAGE_TYPE,
  STUDY_STAGE,
  UNIVERSITY_TYPE,
} from "@Constants/Breadcrumb";
import { useHeroContext } from "@Context/Hero.context";
import { mq } from "@Utils/StyleUtil";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import MagnifierSvgIcon from "public/svgs/icons/global-icons/MagnifierSvgIcon";
import { Fragment, ReactElement } from "react";
import Skeleton from "react-loading-skeleton";
import { CategoriesType } from "types/categories";
import {
  CTAButtonBoxContainer,
  CTAContainer,
  CTAHolder,
  HeroDropdownListItem,
  HeroDropdwon,
} from "../../HeroArea.styled";
import Dropdown from "../Dropdown";
import HeroError from "../HeroError";
import { useAuth } from "@Context/Auth.context";
import { ExploreEvent, filterCategoriesByType } from "@Utils/Analytics";
import { UserProfile } from "types/users";
import { _GetBreadCrumbs } from "@Services/Home";
import { __explore } from "@repo/analytics";

interface Props {}

export default function CTADesktop({}: Props): ReactElement {
  const router = useRouter();
  const { userData } = useAuth();
  const { t } = useTranslation("common");
  const {
    isCountriesLoadingValue,
    currentSelectedCountry,
    countriesHandler,
    currentSelectedStudyStage,
    currentSelectedCollege,
    currentSelectedStep,
    isError,
    isCategoriesLoading,
    categoriesData,
    breadcrumb,
    setIsCTAMobileLayer,
    handleElementClick,
    setCurrentSelectedValue,
    errorHandler,
    CTAURL,
  } = useHeroContext();
  const categoriesArray = CTAURL?.split("/");
  const lastCategorySlug = categoriesArray && categoriesArray[categoriesArray?.length - 1];

  const handleListItem = ({ item, step, clickedDropdown }) => {
    setCurrentSelectedValue({ ...item, step });
    setTimeout(() => document.getElementById(clickedDropdown).click(), 300);
  };

  const searchHandler = async (e: React.MouseEvent<Element>, catSlug: string) => {
    e.preventDefault();
    try {
      const response = await _GetBreadCrumbs(catSlug);
      __explore(
        {
          country_selected: filterCategoriesByType(response?.data, COUNTRY_TYPE),
          stage_selected: filterCategoriesByType(response?.data, STAGE_TYPE),
          university_selected: filterCategoriesByType(response?.data, UNIVERSITY_TYPE),
          college_selected: filterCategoriesByType(response?.data, COLLEGE_TYPE),
          grade_selected: filterCategoriesByType(response?.data, GRADE_TYPE),
          department_selected: filterCategoriesByType(response?.data, DEPARTMENT_TYPE),
          trigger_source: LANDING_CTA,
        },
        userData
      );
    } catch (error) {
      console.log("Error in explore analytics", error);
    }
    setTimeout(() => {
      router.push(CTAURL, undefined, {
        shallow: true,
        scroll: true,
      });
    }, 1100);
  };

  return (
    <Fragment>
      <CTAHolder>
        <CTAContainer>
          <Dropdown currentSelectedValue={currentSelectedCountry.name} step={COUNTRY} dropdownTitle={t("country")}>
            <HeroDropdwon>
              {isCountriesLoadingValue ? (
                <Skeleton count={4} />
              ) : (
                countriesHandler &&
                (countriesHandler?.options as CategoriesType[])?.map((country, index) => (
                  <HeroDropdownListItem
                    onClick={() =>
                      handleListItem({
                        item: country,
                        step: COUNTRY,
                        clickedDropdown: STUDY_STAGE,
                      })
                    }
                    key={index}
                  >
                    {country.name}
                  </HeroDropdownListItem>
                ))
              )}
            </HeroDropdwon>
          </Dropdown>

          <Dropdown
            currentSelectedValue={currentSelectedStudyStage.name || t("choose")}
            dropdownTitle={t("study-stage")}
            isOverlay={!errorHandler(currentSelectedCountry)}
            step={STUDY_STAGE}
          >
            <HeroDropdwon>
              {isCategoriesLoading ? (
                <Skeleton count={4} />
              ) : (
                currentSelectedStudyStage &&
                currentSelectedStudyStage.children &&
                (currentSelectedStudyStage.children as CategoriesType[])?.map((category, index) => (
                  <HeroDropdownListItem
                    onClick={() =>
                      handleListItem({
                        item: category,
                        step: STUDY_STAGE,
                        clickedDropdown: COLLEGE_OR_GRADE,
                      })
                    }
                    key={index}
                  >
                    {category.name || t("choose")}
                  </HeroDropdownListItem>
                ))
              )}
            </HeroDropdwon>
          </Dropdown>

          <CTAButtonBoxContainer>
            <Dropdown
              currentSelectedValue={currentSelectedCollege.name || t("choose")}
              dropdownTitle={t("college-or-grade")}
              isOverlay={!errorHandler(currentSelectedStudyStage)}
              step={COLLEGE_OR_GRADE}
            >
              <HeroDropdwon>
                {isCategoriesLoading ? (
                  <Skeleton count={4} />
                ) : (
                  currentSelectedCollege &&
                  currentSelectedCollege.children &&
                  (currentSelectedCollege.children as CategoriesType[])?.map((college, index) => (
                    <HeroDropdownListItem
                      onClick={() =>
                        handleListItem({
                          item: college,
                          step: COLLEGE_OR_GRADE,
                          clickedDropdown: "main_heroArea",
                        })
                      }
                      key={index}
                    >
                      {college.name || t("choose")}
                    </HeroDropdownListItem>
                  ))
                )}
              </HeroDropdwon>
            </Dropdown>
            <CommonButton
              onClick={async (e) => {
                if (currentSelectedCountry.name && CTAURL) {
                  await searchHandler(e, lastCategorySlug);
                } else {
                  setIsCTAMobileLayer(false);
                }
              }}
              variant="rounded"
              isGaped
            >
              <MagnifierSvgIcon color="white" width="20" height="20" />
              {mq(576) && t("search")}
            </CommonButton>
          </CTAButtonBoxContainer>
        </CTAContainer>
        {isError && <HeroError collegeMessage={`Please select your ${currentSelectedStep} first`} />}
      </CTAHolder>
    </Fragment>
  );
}

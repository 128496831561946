const SectionOneSvgImg = ({ color }: { color: string }) => {
  return (
    <svg width="125" height="85" viewBox="0 0 125 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_416_103523)">
        <path
          d="M25.4236 38.5948C26.2971 40.7309 27.1743 42.8708 28.0478 45.0069C28.6388 46.4532 31.025 45.8191 30.4266 44.3542C29.5531 42.2181 28.6759 40.0782 27.8025 37.9421C27.2115 36.4958 24.8252 37.1299 25.4236 38.5948Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M31.6571 39.4252C32.248 40.6936 32.8428 41.9619 33.4337 43.2339C33.7162 43.8347 34.586 43.9868 35.1212 43.6752C35.742 43.3155 35.846 42.5961 35.5635 41.9916L33.7869 38.1829C33.5044 37.5821 32.6346 37.43 32.0994 37.7416C31.4786 38.1013 31.3746 38.8207 31.6571 39.4252Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M9.32196 16.603C5.43778 19.4289 4.22607 25.9967 3.12586 30.3098C1.45324 36.8776 -0.208218 44.1575 0.0222307 50.9887C0.15604 55.0162 1.54988 56.1287 5.19247 57.7197C11.764 60.5901 18.9079 62.4592 25.8734 64.1132C30.0364 65.0997 36.199 67.3063 40.4623 65.6931C46.9409 63.2417 50.8177 53.1619 57.8835 52.3645C63.5332 51.7267 62.9125 67.31 64.7264 71.3375C66.9788 76.344 72.0821 76.8373 76.9439 78.1167C84.9278 80.2158 92.9675 82.1628 101.048 83.865C104.163 84.5214 107.616 86.1087 110.184 83.7908C114.076 80.2788 116.406 73.6516 118.529 69.0233C121.045 63.5347 124.13 57.4156 124.944 51.3744C125.736 45.5 118.737 44.9623 114.262 43.5085C102.431 39.6665 90.4586 36.2472 78.4864 32.8687C63.1355 28.5371 47.7363 24.3427 32.2368 20.5674C27.0182 19.2954 21.7848 18.0271 16.4993 17.0628C14.4364 16.6883 11.37 15.7018 9.32568 16.6067C7.8798 17.2483 9.1324 19.3696 10.5708 18.7317C11.7045 18.231 15.1129 19.2954 16.4547 19.5476C20.5619 20.3189 24.632 21.2758 28.6983 22.2437C40.2913 25.0066 51.8101 28.0698 63.3065 31.2073C75.1114 34.43 86.8866 37.7603 98.6135 41.2537C103.26 42.637 107.898 44.0426 112.515 45.526C115.18 46.3826 118.886 46.9908 121.246 48.5336C123.781 50.1913 122.19 52.8059 121.22 55.7875C119.25 61.8288 116.603 67.6994 113.708 73.3512C111.965 76.752 110.103 82.6486 105.921 82.3296C99.684 81.8549 93.1608 79.6038 87.1022 78.1315C83.928 77.3602 80.7612 76.5703 77.6018 75.7395C74.9442 75.0423 70.9336 74.5825 68.7406 72.8654C64.1279 69.2533 65.4623 59.8595 63.9458 54.7158C60.3701 42.5888 48.3162 56.2845 43.6552 60.3602C41.1351 62.5631 39.4216 63.8796 36.0726 63.5755C32.4338 63.2417 28.78 62.296 25.2415 61.4282C18.644 59.8076 10.6601 58.3168 4.74644 54.827C0.234095 52.1643 3.03665 43.1377 3.80977 38.8469C4.75759 33.5919 5.83921 22.1695 10.5708 18.728C11.842 17.8045 10.6117 15.6684 9.32568 16.603H9.32196Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M10.27 18.8502C18.1944 15.9093 26.171 13.0983 34.1846 10.4096C40.0016 8.45886 45.8409 6.55267 51.7471 4.89494C54.9511 3.99748 61.0096 1.3644 64.2396 2.85524C67.4027 4.3127 67.6332 10.4541 67.4399 13.3282C67.3284 14.997 67.0348 18.3125 65.6818 19.5511C61.6638 23.2374 61.3776 19.3138 60.8684 17.1443C60.2551 14.5223 60.5636 11.6668 57.4525 11.4925C53.4829 11.2737 47.257 14.7745 43.5067 16.0466C39.2731 17.4781 35.047 18.9244 30.8245 20.3819C29.3341 20.8973 29.9734 23.2745 31.4787 22.7553C35.1027 21.5055 38.7267 20.2632 42.3581 19.0319C45.1272 18.09 47.9001 17.1517 50.6766 16.232C52.1708 15.735 55.6498 13.8363 57.2332 14.1144C58.6531 14.3629 57.2407 13.6879 57.9023 14.7337C58.3855 15.4977 58.2926 16.9218 58.4933 17.797C58.8613 19.4288 59.2367 21.5723 60.5413 22.785C63.0019 25.0769 66.2133 23.1781 67.8153 20.7935C71.2237 15.7165 71.5174 0.789578 63.2324 0.0441594C59.8462 -0.259942 55.8506 1.2309 52.6503 2.0987C47.0303 3.6192 41.4772 5.40302 35.9502 7.23504C27.1188 10.1574 18.3394 13.2466 9.61951 16.4842C8.14389 17.033 8.7832 19.4102 10.2737 18.8576L10.27 18.8502Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M124.77 48.0113C123.157 40.9799 121.131 33.967 118.525 27.2397C116.027 20.7831 112.731 11.6008 104.245 12.5835C100.535 13.0137 87.0467 21.1762 93.41 25.6895C97.5209 28.6082 102.256 22.7338 103.929 29.1756C104.955 33.1252 104.46 38.0427 104.546 42.1296C104.579 43.7094 107.044 43.7168 107.01 42.1296C106.947 39.1256 106.847 36.118 106.676 33.1178C106.553 30.9742 106.969 26.5425 105.438 24.6994C103.951 22.9081 101.13 23.52 99.1341 23.661C96.6586 23.8316 94.2501 24.132 95.2053 21.1985C96.0862 18.495 100.747 15.9027 103.293 15.2648C112.493 12.9581 115.99 26.995 118.098 33.37C119.759 38.3913 121.209 43.5054 122.395 48.664C122.748 50.2068 125.127 49.5541 124.773 48.0113H124.77Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M15.8935 26.3791C13.5482 27.7884 13.0613 32.183 12.5483 34.5342C11.7864 38.0277 10.058 43.7314 11.0616 47.2954C11.6711 49.4612 12.9906 49.806 15.0907 50.5515C19.8632 52.2426 24.9182 53.3922 29.884 54.349C32.497 54.8534 36.8644 56.2218 39.4923 55.113C42.4509 53.8595 43.9489 49.2349 45.0602 46.5611C46.1902 43.839 49.1934 38.1427 48.1676 35.0386C47.5468 33.1658 45.5955 32.8839 43.8336 32.3017C38.5259 30.5513 33.0583 29.1828 27.613 27.933C24.1005 27.1246 19.8446 25.7005 16.1909 26.253C14.6261 26.4904 15.2914 28.8602 16.8451 28.6265C20.1383 28.1259 24.4016 29.7057 27.5721 30.4474C31.683 31.4042 35.7716 32.4686 39.8231 33.6516C41.2541 34.0707 43.4099 34.3451 44.6588 35.1758C46.2199 36.2142 45.9746 34.868 45.5062 37.3379C44.696 41.6324 42.9007 46.2829 40.6966 50.0323C38.2583 54.1784 34.7569 52.7877 30.5382 51.9756C26.1931 51.1374 21.8481 50.099 17.6219 48.7825C16.1612 48.3263 14.1466 48.0667 13.4738 46.6649C12.9349 45.5449 13.6522 42.9267 13.7935 41.7511C14.102 39.2144 14.6112 30.0209 17.1387 28.5041C18.4954 27.6883 17.2577 25.5595 15.8935 26.3791Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M109.393 52.1048C110.883 54.6526 107.397 62.2514 106.468 64.7362C104.628 69.6685 104.003 69.8985 99.004 68.9602C94.6626 68.148 90.3436 67.1134 86.11 65.8636C81.7129 64.5656 78.3565 64.1614 78.3342 59.0287C78.3156 54.8937 78.7282 49.583 80.4231 45.7447C81.252 43.8607 80.3079 44.6284 82.3894 44.6877C83.8947 44.7285 85.5599 45.3108 87.0206 45.6408C91.5478 46.6533 96.0527 47.8141 100.506 49.1083C103.442 49.9613 107.363 50.6437 109.839 52.5499C111.095 53.5178 112.325 51.378 111.084 50.4249C107.527 47.688 101.662 46.835 97.4503 45.6965C92.8525 44.4541 86.2996 41.6504 81.4638 42.0769C78.048 42.3773 77.7915 45.5259 77.1262 48.6559C76.275 52.6611 74.2456 60.2155 76.5501 64.0056C78.4903 67.1949 84.6678 68.0516 87.8941 68.9231C92.2578 70.1025 99.3943 73.0174 104 72.1162C107.36 71.4598 108.259 67.1096 109.27 64.2207C110.374 61.061 113.481 54.2113 111.526 50.8662C110.727 49.4977 108.594 50.7364 109.396 52.1086L109.393 52.1048Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M110.456 51.4785C108.096 49.2571 82.1849 42.8339 80.4119 43.253C78.6464 43.6832 75.6617 61.258 77.8844 63.8132C80.096 66.3795 102.765 71.5455 104.68 70.8112C106.583 70.0769 112.054 52.9916 110.459 51.4748L110.456 51.4785ZM93.0494 60.8575C92.3061 60.9836 91.7745 60.494 91.5627 59.8339C91.0051 58.0909 90.4476 56.3479 89.8901 54.6086C89.6819 53.9522 90.3026 53.229 90.9159 53.1251C91.6593 52.9991 92.1908 53.4886 92.4027 54.1487C92.9602 55.8917 93.5178 57.6347 94.0753 59.3741C94.2835 60.0305 93.6627 60.7536 93.0494 60.8575ZM98.7103 61.6251C97.454 59.0292 96.7143 56.1254 96.0416 53.3328C95.6587 51.7307 98.1751 51.2857 98.5542 52.873C99.1749 55.4615 99.8551 58.1317 101.019 60.5385C101.736 62.022 99.424 63.1049 98.7103 61.6288V61.6251Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M20.5174 13.8591C20.0751 15.2758 19.6291 16.6887 19.1867 18.1054C18.711 19.6185 21.0935 20.2675 21.5656 18.7581L22.8962 14.5118C23.372 12.9987 20.9895 12.3497 20.5174 13.8591Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M25.4943 12.294C24.9888 14.3745 24.4796 16.4513 23.9741 18.5318C23.5987 20.0709 25.9775 20.7273 26.3529 19.1845C26.8584 17.104 27.3677 15.0272 27.8732 12.9467C28.2486 11.4077 25.8697 10.7513 25.4943 12.294Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M31.6756 10.0312C30.7835 13.406 29.8878 16.7808 28.9957 20.1593C28.5906 21.6909 30.9694 22.3473 31.3745 20.812C32.2666 17.4372 33.1624 14.0624 34.0544 10.684C34.4596 9.15232 32.0808 8.49962 31.6756 10.0312Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M37.9572 8.19993C37.0985 11.5636 36.2362 14.9235 35.3776 18.2872C34.9836 19.8225 37.3624 20.4752 37.7564 18.9399C38.615 15.5762 39.4774 12.2163 40.336 8.85263C40.73 7.31729 38.3511 6.66459 37.9572 8.19993Z"
          fill={color ? color : "#033447"}
        />
      </g>
      <defs>
        <clipPath id="clip0_416_103523">
          <rect width="125" height="85" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SectionOneSvgImg;

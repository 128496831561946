import InformationSvgIcon from "public/svgs/icons/global-icons/InformationSvgIcon";
import { HeroErroMessage, HeroErrorContainer, HeroErrorContent } from "../../HeroArea.styled";

interface Props {
  collegeMessage: string;
}

export default function HeroError({ collegeMessage }: Props) {
  return (
    <HeroErrorContainer>
      <HeroErrorContent>
        <InformationSvgIcon />
        <HeroErroMessage>{collegeMessage}</HeroErroMessage>
      </HeroErrorContent>
    </HeroErrorContainer>
  );
}

import { useHeroContext } from "@Context/Hero.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import {
  DropdownContainer,
  DropdownHandler,
  DropdownTitle,
  DropdownWrapper,
  HeroDropdownBlockerOverlay,
} from "../../HeroArea.styled";

interface Props {
  children: ReactNode;
  dropdownTitle: string;
  currentSelectedValue: ReactNode | string;
  isOverlay?: Boolean;
  step: string;
}

export default function Dropdown({ children, dropdownTitle, currentSelectedValue, isOverlay = false, step }: Props) {
  const { t } = useTranslation("common");
  const { locales, locale, asPath } = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownItemRef = useRef(null);
  const { setIsError } = useHeroContext();

  const handleToggleDropdown = (visibile) => {
    setIsDropdownOpen(visibile);
  };

  useClickAway(
    dropdownRef,
    (e) => {
      if (e.target instanceof HTMLInputElement) {
        setIsDropdownOpen(false);
      } else {
        handleToggleDropdown(false);
      }
    },
    ["click", "mousedown", "touchstart"]
  );

  useEffect(() => {
    handleToggleDropdown(false);
  }, [locale]);

  return (
    <DropdownContainer ref={dropdownRef} className={"dropdown-hero-area"}>
      {isOverlay && <HeroDropdownBlockerOverlay onClick={() => setIsError(true)} />}
      <DropdownWrapper
        id={step}
        type="button"
        isOpen={isDropdownOpen}
        onClick={() => handleToggleDropdown(!isDropdownOpen)}
      >
        <DropdownTitle fontSize={"h6"} color={"nero"}>
          {dropdownTitle}
        </DropdownTitle>
        <DropdownTitle fontSize={"body16"} color={"gray2"} width="138px">
          {currentSelectedValue}
        </DropdownTitle>
      </DropdownWrapper>
      <DropdownHandler as={"div"} isOpen={isDropdownOpen}>
        {children}
      </DropdownHandler>
    </DropdownContainer>
  );
}

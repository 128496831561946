import { grid } from "@Style/grid";
import { mw } from "@Utils/StyleUtil";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";
import { LandingBGColors } from "types/common";

export const PlatformWrapper = styled.div<{ readonly commonBG?: LandingBGColors }>`
  position: relative;
  height: 100%;
  padding: 60px 0;
  transition: 0.9s ease background-color;
`;

export const PlatformContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const ParallaxPhoneImg = styled.img`
  z-index: 2;
  margin: auto;
  height: 95vh;
  @media only screen and (max-width: ${grid.breakpoints.lg}px) {
    margin-left: -7em;
  }
  @media only screen and (min-width: ${grid.breakpoints.md}px) and (max-width: ${grid.breakpoints.lg}px) {
    height: 700px;
    margin: auto;
  }
  @media only screen and (max-width: ${grid.breakpoints.xl}px) {
    margin: auto;
  }
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    width: 16em;
    height: auto;
    margin: auto;
  }
  @media only screen and (max-width: ${grid.breakpoints.sm}px) {
    width: 10.6em;
    height: auto;
    margin: auto;
  }
`;

export const ParallaxDevicesImg = styled.img`
  z-index: -2;
  height: auto;
  max-height: 47vh;
  width: auto;
  @media only screen and (max-width: ${grid.breakpoints.xl}px) {
    max-height: 370px;
  }
  @media only screen and (max-width: ${grid.breakpoints.lg}px) {
    height: 330px;
    max-margin: auto;
  }

  @media only screen and (min-width: ${grid.breakpoints.md}px) and (max-width: ${grid.breakpoints.lg}px) {
    height: 290px;
    margin: auto;
  }

  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    height: 250px;
    margin: auto;
  }
`;

export const ParallaxPhoneHolder = styled(Parallax)`
  position: sticky;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    position: absolute;
    justify-content: center;
    bottom: 49vh;
    right: 0;
    left: 0;
  }
`;

export const ParallaxDevicesHolder = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
`;

export const PlatformHeader = styled.div`
  text-align: center;
  margin-bottom: 36px;
  display: block;
  width: 100%;
`;

export const PlatformFooter = styled.div`
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const SocialImage = styled.img`
  height: 52px;
  width: auto;
  margin: 0 6px;
  cursor: pointer;
  ${mw(1920)} {
    height: calc(52px * 1.2);
  }
`;

export const PlatformHeaderHeadline = styled.h1`
  font-size: ${({ theme }) => theme.fontsizes.h4};
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.fontsizes.h6};
  }
`;

export const PlatformHeaderParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body18};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  padding: 0 5em;

  @media only screen and (min-width: ${grid.breakpoints.md}px) and (max-width: ${grid.breakpoints.lg}px) {
    font-size: ${({ theme }) => theme.fontsizes.body18};
  }

  @media only screen and (max-width: ${grid.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.fontsizes.body14};
    padding: 0 0.5em;
  }

  ${mw(1920)} {
    font-size: calc(${({ theme }) => theme.fontsizes.body18} * 1.2);
  }
`;

export const PlatformImageContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
  height: fit-content;
`;
export const PlatformImage = styled.img`
  width: 53em;
  height: auto;
  margin: auto;
  ${mw(1920)} {
    width: calc(53em * 1.2);
  }

  @media only screen and (max-width: ${grid.breakpoints.lg}px) {
    width: 100%;
  }
`;

export const PlatformPicture = styled.picture`
  width: 100%;
  img {
    width: 100%;
  }
`;

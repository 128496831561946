// @ts-nocheck
import CurrentBreadcrumb from "@Components/CurrentBreadcrumb";
import { CommonButton } from "@Components/common/CommonButton";
import { COLLEGE_OR_GRADE, COUNTRY, LANDING_CTA, STUDY_STAGE } from "@Constants/Breadcrumb";
import { useHeroContext } from "@Context/Hero.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import CloseSvgIcon from "public/svgs/icons/global-icons/CloseSvgIcon";
import LightArrowLeftSvgIcon from "public/svgs/icons/global-icons/LightArrowLeftSvgIcon";
import LightArrowRightSvgIcon from "public/svgs/icons/global-icons/LightArrowRightSvgIcon";
import { Fragment, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { CategoriesType } from "types/categories";
import {
  ArrowDirectionWrapper,
  CTAMobileItem,
  CTAMobileItemIconHolder,
  CTAMobileItemImageHolder,
  CTAMobileItemWrapper,
  CTAMobileItemsContainer,
  CTAMobileLayer,
  CTAMobileLayerButtonHolder,
  CTAMobileLayerContainer,
  CTAMobileLayerFooterButtonHolder,
  CTAMobileLayerHeader,
  CTAMobileLayerHeaderContainer,
  CTAMobileLayerHeaderHeadline,
  CTAMobileLayerHeaderMessage,
} from "../../HeroArea.styled";
import { useAuth } from "@Context/Auth.context";
import { ExploreEvent } from "@Utils/Analytics";
import { UserProfile } from "types/users";

interface Props {}

export default function CTAMobileLayerHandler({}: Props) {
  const router = useRouter();
  const { userData } = useAuth();
  const { t } = useTranslation("common");
  const {
    countriesHandler,
    currentSelectedStudyStage,
    currentSelectedCountry,
    currentSelectedCollege,
    currentSelectedStep,
    isCTAMobileLayer,
    categoriesData,
    isCategoriesLoading,
    breadcrumb,
    currentSelectedValue,
    setBreadcrumb,
    setCurrentSelectedStep,
    handleElementClick,
    setIsCTAMobileLayer,
    setCurrentSelectedValue,
    CTAURL,
  } = useHeroContext();
  const categoriesArray = CTAURL?.split("/");
  const lastCategorySlug = categoriesArray && categoriesArray[categoriesArray?.length - 1];

  // Country Initial Object
  const CountryInitial = {
    id: 0,
    code: "Choose",
    name: "Choose",
    emoji: "",
  };

  const SkeletonLoader = (variant) => (
    <Fragment>
      {[...Array(4)].map((e, i) => (
        <CTAMobileItem key={i} variant={variant}>
          <Skeleton count={1} />
        </CTAMobileItem>
      ))}
    </Fragment>
  );

  const currentStep = (item) => currentSelectedStep === item;

  const handleBackButton = () => {
    breadcrumb.pop();
    if (breadcrumb.length > 0) {
      setCurrentSelectedValue({
        ...breadcrumb[breadcrumb.length - 1],
        step: currentStep(COUNTRY) ? COUNTRY : currentStep(STUDY_STAGE) ? STUDY_STAGE : COLLEGE_OR_GRADE,
      });
      setBreadcrumb(breadcrumb);
    } else {
      setCurrentSelectedValue({ ...CountryInitial, step: COUNTRY });
      setCurrentSelectedStep(COUNTRY);
      setBreadcrumb([{ ...CountryInitial, step: COUNTRY }]);
    }
  };

  useEffect(() => {
    if (isCTAMobileLayer) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isCTAMobileLayer]);

  const searchHandler = (e: React.MouseEvent<Element>, catSlug: string) => {
    e.preventDefault();
    ExploreEvent(catSlug, LANDING_CTA, userData as UserProfile);
    setTimeout(() => {
      router.push(CTAURL, undefined, {
        shallow: true,
        scroll: true,
      });
    }, 1100);
  };

  return (
    <Fragment>
      {isCTAMobileLayer && (
        <CTAMobileLayer id="cta-mobile-layer">
          <CTAMobileLayerContainer>
            <CTAMobileLayerHeader>
              <CTAMobileLayerHeaderContainer>
                <span>
                  {currentSelectedStep !== COUNTRY && breadcrumb.length > 0 && (
                    <ArrowDirectionWrapper onClick={() => handleBackButton()}>
                      <LightArrowLeftSvgIcon width="10" height="15" />
                    </ArrowDirectionWrapper>
                  )}
                </span>

                <CTAMobileLayerHeaderHeadline>
                  {currentStep(COUNTRY) ? countriesHandler?.title : categoriesData?.title}
                </CTAMobileLayerHeaderHeadline>
                <ArrowDirectionWrapper onClick={() => setIsCTAMobileLayer(false)}>
                  <CloseSvgIcon />
                </ArrowDirectionWrapper>
              </CTAMobileLayerHeaderContainer>
              <CTAMobileLayerHeaderMessage>
                {currentStep(COUNTRY) ? countriesHandler?.subtitle : categoriesData?.subtitle}
              </CTAMobileLayerHeaderMessage>
              {isCategoriesLoading ? (
                <Skeleton count={1} />
              ) : (
                currentSelectedStep !== COUNTRY && <CurrentBreadcrumb breadcrumb={breadcrumb} fontSize={"body16"} />
              )}
            </CTAMobileLayerHeader>

            <CTAMobileItemsContainer>
              {isCategoriesLoading ? (
                <SkeletonLoader variant={currentStep(COLLEGE_OR_GRADE) ? "fit-content" : "default"} />
              ) : (
                <Fragment>
                  {(countriesHandler?.options && currentStep(COUNTRY)) || categoriesData?.options ? (
                    (
                      (currentStep(COUNTRY) ? countriesHandler?.options : categoriesData?.options) as
                        | CategoriesType[]
                        | []
                    )?.map((category, index) => (
                      <CTAMobileItemWrapper key={index}>
                        <CTAMobileItem
                          variant={currentStep(COLLEGE_OR_GRADE) ? "fit-content" : "default"}
                          onClick={() =>
                            setCurrentSelectedValue({
                              ...category,
                              step: currentStep(COUNTRY)
                                ? COUNTRY
                                : currentStep(STUDY_STAGE)
                                  ? STUDY_STAGE
                                  : COLLEGE_OR_GRADE,
                            })
                          }
                        >
                          {currentStep(COUNTRY) ? (
                            <CTAMobileItemIconHolder>{category.emoji}</CTAMobileItemIconHolder>
                          ) : (
                            category.image?.url && (
                              <CTAMobileItemImageHolder
                                src={category.image.url}
                                alt={`${category.name} image`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = "/images/icons/circle.svg";
                                }}
                              />
                            )
                          )}
                          {category.name}
                        </CTAMobileItem>
                        <ArrowDirectionWrapper>
                          <LightArrowRightSvgIcon />
                        </ArrowDirectionWrapper>
                      </CTAMobileItemWrapper>
                    ))
                  ) : (
                    <CTAMobileLayerButtonHolder>
                      <CommonButton onClick={() => handleBackButton()} variant="default">
                        No result, BACK step
                      </CommonButton>
                    </CTAMobileLayerButtonHolder>
                  )}
                </Fragment>
              )}
            </CTAMobileItemsContainer>

            <CTAMobileLayerFooterButtonHolder>
              <CommonButton
                onClick={(e) => {
                  if (CTAURL) {
                    searchHandler(e, lastCategorySlug);
                  }
                }}
                variant="default"
                disabled={currentSelectedStep == COUNTRY}
              >
                {t("search")}
              </CommonButton>
            </CTAMobileLayerFooterButtonHolder>
          </CTAMobileLayerContainer>
        </CTAMobileLayer>
      )}
    </Fragment>
  );
}

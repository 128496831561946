import { CommonButton } from "@Components/common/CommonButton";
import { mq, mw } from "@Utils/StyleUtil";
import styled, { css, keyframes } from "styled-components";
import { LandingBGColors } from "types/common";

const resetRadius = keyframes`
  from {
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
  } to {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const Wrapper = styled.section<{
  readonly radius?: "top" | "bottom";
  readonly commonBG?: LandingBGColors;
}>`
  height: 85vh;
  max-height: 700px;
  padding: 24px;
  z-index: 1;
  display: flex;
  align-items: center;
  transition:
    0.9s ease background-color,
    0.5s ease border;
  ${mw(1920)} {
    transform: scale(1.2, 1.2);
  }
  ${mq(576)} {
    height: 100vh;
  }
  ${({ radius }) =>
    radius &&
    (radius === "top"
      ? css`
          border-top-left-radius: 28px;
          border-top-right-radius: 28px;
        `
      : css`
          border-bottom-left-radius: 28px;
          border-bottom-right-radius: 28px;
        `)}

  &.reset-border {
    animation: ${resetRadius} 0.5s linear forwards 0.8s;
  }
`;
export const Inner = styled.div<{ readonly direction?: "ltr" | "rtl" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    flex: 1;
  }
  gap: 58px;
  ${mq(992)} {
    gap: 53px;
  }
  ${mq(576)} {
    gap: 32px;
    flex-direction: column-reverse;
  }
  ${({ direction }) =>
    direction === "rtl" &&
    css`
      flex-direction: row-reverse;
    `}
`;
export const InfoSide = styled.div`
  flex: 1;
  max-width: 440px;
  ${mq(992)} {
    max-width: 398px;
  }
  ${mq(768)} {
    max-width: 334px;
  }
  ${mq(576)} {
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const SectionIcon = styled.div`
  display: block;
  margin-bottom: 20px;
  object-fit: fill;
  ${mq(576)} {
    margin: 0 auto 20px auto;
  }
`;
export const SectionTitle = styled.h2<{
  readonly isFoundingDay?: boolean;
}>`
  font-size: 3.6rem;
  line-height: 1.555;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  &.sec1__title {
    font-size: 3rem;
    font-weight: 700;
    &::before {
      position: absolute;
      ${({ isFoundingDay }) =>
        isFoundingDay
          ? css`
              content: url("/images/landing-common-sections/ksaFounding/sec1_quote_brown.svg");
            `
          : css`
              content: url("/images/landing-common-sections/sec1_quote_seagule.svg");
            `}
      left: -20px;
      top: -12px;
      width: 21.3px;
      height: 35.28px;
    }
    &::after {
      position: absolute;
      ${({ isFoundingDay }) =>
        isFoundingDay
          ? css`
              content: url("/images/landing-common-sections/ksaFounding/sec1_underline_brown.svg");
            `
          : css`
              content: url("/images/landing-common-sections/sec1_underline_seagule.svg");
            `}
      /* bottom: -3.98px; */
      bottom: -20px;
      left: 0;
      width: 186px;
      /* height: 7px; */
    }
    &:lang(ar) {
      &::before,
      &::after {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
  &.sec2__title {
    font-weight: 700;
    font-size: 3rem;
    &::after {
      position: absolute;
      ${({ isFoundingDay }) =>
        isFoundingDay
          ? css`
              content: url("/images/landing-common-sections/ksaFounding/sec2_underline_brown.svg");
            `
          : css`
              content: url("/images/landing-common-sections/sec2_underline_seagule.svg");
            `}
      bottom: -20px;
      right: 0;
      width: 111.57px;
    }
    &:lang(ar) {
      &::after {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
  &.sec3__title {
    font-weight: 700;
    font-size: 3rem;
    &::before {
      position: absolute;
      ${({ isFoundingDay }) =>
        isFoundingDay
          ? css`
              content: url("/images/landing-common-sections/ksaFounding/sec3_quote_brown.svg");
            `
          : css`
              content: url("/images/landing-common-sections/sec3_quote_seagule.svg");
            `}
      left: -20px;
      top: -12px;
      width: 21.3px;
      height: 35.28px;
    }
    &:lang(ar) {
      &::before {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
  .underlined {
    background: url("/images/icons/underline.svg") no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding-bottom: 4px;
    white-space: nowrap;
    &:lang(ar) {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      padding-bottom: 28px;
    }
  }
  ${mq(992)} {
    font-size: 2.8rem;
    margin-bottom: 35px;
    max-width: 310px;
  }
  ${mq(768)} {
    font-size: 2.4rem;
    line-height: 1.5;
    max-width: 220px;
    margin-bottom: 31px;
  }
  ${mq(576)} {
    max-width: none;
    text-align: center;
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 23px;
  }
`;

export const Desc = styled.p`
  font-size: 2rem;
  color: inherit;
  line-height: 1.6;
  margin-bottom: 36px;
  ${mq(992)} {
    font-size: 1.8rem;
    margin-bottom: 32px;
  }
  ${mq(768)} {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 28px;
  }
  ${mq(576)} {
    text-align: center;
    line-height: 1.5;
    margin: auto;
    margin-bottom: 32px;
  }
`;

export const CtaBtn = styled(CommonButton)`
  width: 100%;
  gap: 12px;
  min-width: 220px;
  max-width: 220px;
  font-size: 1.8rem;
  line-height: 1.55555556;
  padding: 14px;
  font-weight: 500;
  ${mq(992)} {
    font-size: 1.6rem;
    min-width: 200px;
    max-width: 200px;
    padding: 12px;
    line-height: 1.5;
  }
  ${mq(576)} {
    min-width: 212px;
    max-width: 212px;
    margin: auto;
  }
`;
export const CtaBtnIcon = styled.img`
  transform: none /*rtl:scale(-1, 1) */;
  height: 11px;
  width: 13.33px;
  object-fit: contain;
`;
export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  img {
    max-height: 48rem;
  }
  .video-player,
  video {
    height: 48rem !important;
    width: 100% !important;
  }
  ${mq(992)} {
    img {
      max-height: 28rem;
    }

    .video-player,
    video {
      height: 28rem !important;
    }
  }
  ${mq(576)} {
    img {
      max-height: 25rem;
    }
    .video-player,
    video {
      height: 25rem !important;
    }
  }
`;

const SectionThreeSvgImg = ({ color }: { color: string }) => {
  return (
    <svg width="99" height="113" viewBox="0 0 99 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_416_102367)">
        <path
          d="M92.6448 39.4634C93.5742 50.3375 94.4686 61.2116 95.2226 72.0998C95.5664 77.0963 95.889 82.0928 96.124 87.0964C96.2222 89.1806 96.5519 91.4453 96.359 93.5225C95.8224 99.3364 92.8061 99.2727 88.2151 99.9415C72.5726 102.213 56.9301 104.481 41.2877 106.753C34.5853 107.726 27.8829 108.735 21.1769 109.673C16.8735 110.274 14.1343 111.523 12.2509 105.104C11.8897 103.869 12.0299 101.842 11.9352 100.483C11.6196 95.8615 11.3039 91.2365 10.9883 86.6151C10.4622 78.9116 9.9361 71.2046 9.41 63.501C9.15047 59.6723 9.12591 55.7409 8.62087 51.944C8.442 50.5993 6.78305 51.4486 7.89837 50.7373C10.1956 49.2653 16.2843 49.7536 18.9288 49.4422C33.4419 47.7366 47.955 46.031 62.4681 44.3254C72.9128 43.0975 83.3575 41.8696 93.8022 40.6417C95.2717 40.4683 95.2893 38.1187 93.8022 38.2956C64.9688 41.6856 36.132 45.072 7.29862 48.462C6.7094 48.5328 6.08861 48.9433 6.1342 49.6368C6.92334 61.1939 7.71248 72.7509 8.50162 84.308C8.92249 90.4722 9.29777 96.64 9.76424 102.801C9.96065 105.387 9.78528 108.745 11.7459 110.801C13.7134 112.864 16.6946 112.603 19.2479 112.312C23.8319 111.792 28.3984 110.985 32.9649 110.324C44.1391 108.703 55.3098 107.079 66.484 105.458C71.6993 104.701 76.9111 103.944 82.1265 103.186C86.1703 102.599 92.7044 102.949 96.2468 100.741C100.477 98.1049 98.6107 90.4828 98.4108 86.3957C97.6462 70.7304 96.2994 55.0933 94.9666 39.4705C94.8403 37.9772 92.5115 37.963 92.6413 39.4705L92.6448 39.4634Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M8.12989 49.3711C7.5582 47.8283 3.86153 40.6485 4.4753 39.3322C4.9067 38.4015 8.45957 37.0533 9.41355 36.5154C13.2786 34.3427 17.1682 32.2125 21.0647 30.107C29.5068 25.5423 38.0049 21.0801 46.5381 16.6887C53.6684 13.0227 60.8198 9.38151 68.0553 5.92784C69.9808 5.00781 72.6112 3.1819 74.7472 2.89173C78.279 2.41048 79.9204 9.02057 80.8323 11.9435C81.2812 13.3801 83.5259 12.7679 83.077 11.3207C81.6881 6.85496 79.4575 -1.13873 73.7125 0.796884C67.5362 2.88112 61.5773 6.45509 55.7693 9.37089C37.7699 18.412 19.8197 27.6937 2.4025 37.8247C1.98864 38.0653 1.69052 38.674 1.86939 39.1517C3.20917 42.7646 4.54895 46.381 5.88874 49.9939C6.40781 51.3952 8.65949 50.7901 8.1334 49.3711H8.12989Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M8.29116 62.4785C36.8615 59.5273 65.4283 56.5762 93.9986 53.625C95.4751 53.4728 95.4892 51.1232 93.9986 51.2789C65.4283 54.2301 36.8615 57.1813 8.29116 60.1324C6.81459 60.2846 6.80056 62.6342 8.29116 62.4785Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M8.05615 35.6737L23.2497 41.2258L28.7526 38.5825L14.8673 32.7686L8.05615 35.6737Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M21.5031 48.4481L12.77 60.2563L20.2826 59.3752L27.7917 47.3936L21.5031 48.4481Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M42.112 45.6313L32.3337 58.3171L40.7161 57.2591L50.6698 44.5732L42.112 45.6313Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M62.7208 43.1647L51.8938 56.7316L61.3249 55.1463L71.98 41.9297L62.7208 43.1647Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M82.9824 41.0491L72.678 54.616L81.2357 53.3846L91.3648 40.168L82.9824 41.0491Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M24.9963 26.9512L40.8913 32.942L47.1764 29.7715L30.9342 23.4268L24.9963 26.9512Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M41.2385 18.4938L57.6561 24.6616L63.0713 21.6644L46.6538 15.3232L41.2385 18.4938Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M58.3542 9.68239L74.2492 15.8502L79.3138 13.2068L63.4188 7.03906L58.3542 9.68239Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M15.1585 67.4606C19.8582 66.5335 24.5825 65.9603 29.3664 65.7267C30.857 65.6524 30.8676 63.3063 29.3664 63.3806C24.3756 63.6248 19.4408 64.2334 14.5412 65.1995C13.0716 65.4896 13.6959 67.7508 15.1585 67.4642V67.4606Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M32.6808 63.7201C34.1784 63.7201 34.1819 61.374 32.6808 61.374C31.1797 61.374 31.1797 63.7201 32.6808 63.7201Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M32.8563 66.0102C34.3539 66.0102 34.3574 63.6641 32.8563 63.6641C31.3552 63.6641 31.3552 66.0102 32.8563 66.0102Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M39.4744 64.7677C56.0673 63.1753 72.6567 61.5865 89.2497 59.9941C90.7262 59.8526 90.7438 57.503 89.2497 57.6481C72.6567 59.2404 56.0673 60.8292 39.4744 62.4216C37.9978 62.5632 37.9803 64.9128 39.4744 64.7677Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M16.4071 74.6722C20.8929 74.0388 25.3752 73.4054 29.8611 72.772C31.3376 72.5632 30.7098 70.3021 29.2438 70.5073L15.7898 72.4075C14.3133 72.6163 14.9411 74.8775 16.4071 74.6722Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M32.9053 68.0592L32.73 68.2361C31.6708 69.3047 33.3157 70.9679 34.3749 69.8957L34.5502 69.7188C35.6094 68.6501 33.9645 66.987 32.9053 68.0592Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M33.5542 73.2357H33.7296C35.2272 73.2357 35.2307 70.8896 33.7296 70.8896H33.5542C32.0566 70.8896 32.0531 73.2357 33.5542 73.2357Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M40.702 72.0756C57.2564 70.0656 73.8072 68.0592 90.3616 66.0493C91.8311 65.8724 91.8487 63.5228 90.3616 63.7032C73.8072 65.7132 57.2564 67.7195 40.702 69.7295C39.2325 69.9064 39.2149 72.256 40.702 72.0756Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M17.112 83.5117C21.6399 82.8394 26.1292 81.9901 30.5904 80.9568C32.0495 80.6207 31.4322 78.356 29.9732 78.6921C25.5119 79.7254 21.0226 80.5747 16.4947 81.247C15.0181 81.4664 15.6459 83.7275 17.112 83.5117Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M38.4047 80.9355C44.0409 80.0473 49.6946 79.4139 55.3869 79.0175C56.874 78.9149 56.8845 76.5653 55.3869 76.6715C49.4912 77.0819 43.627 77.7543 37.7874 78.6743C36.3108 78.9079 36.9386 81.169 38.4047 80.939V80.9355Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M68.3148 78.0523C74.8909 78.1867 81.3689 77.5852 87.8153 76.2759C89.2813 75.9787 88.6605 73.714 87.198 74.0112C80.9515 75.2816 74.6875 75.83 68.3183 75.7026C66.8207 75.6708 66.8207 78.0204 68.3183 78.0487L68.3148 78.0523Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M17.6452 87.4295C42.4277 85.6143 67.1294 82.9249 91.7295 79.3828C93.2061 79.1705 92.5783 76.9093 91.1122 77.1181C66.7191 80.6319 42.2207 83.2788 17.6487 85.0799C16.1651 85.1896 16.1511 87.5357 17.6487 87.426L17.6452 87.4295Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M15.9721 86.3354L18.0729 104.308C18.2448 105.791 20.5736 105.808 20.3983 104.308C19.6968 98.3171 18.9988 92.3263 18.2974 86.3354C18.1255 84.8528 15.7967 84.8351 15.9721 86.3354Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M33.8067 84.9238C34.2451 90.6245 34.68 96.3216 35.1184 102.022C35.2341 103.519 37.5595 103.533 37.4437 102.022C37.0053 96.3216 36.5704 90.6245 36.132 84.9238C36.0163 83.427 33.6909 83.4129 33.8067 84.9238Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M58.491 82.7972C59.473 87.9282 60.1218 93.091 60.4445 98.3104C60.5392 99.8107 62.8645 99.8214 62.7698 98.3104C62.4331 92.8822 61.7527 87.5141 60.7321 82.1744C60.448 80.6917 58.2034 81.3216 58.4874 82.7972H58.491Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M90.2212 78.7705C90.6 84.0536 91.0665 89.3297 91.4488 94.6093C91.5575 96.1061 93.8828 96.1202 93.7741 94.6093C93.3953 89.3261 92.9288 84.0501 92.5466 78.7705C92.4378 77.2737 90.1125 77.2595 90.2212 78.7705Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M19.7003 106.905C44.1075 103.183 68.5147 99.4601 92.9218 95.7411C94.3984 95.5146 93.7706 93.2534 92.3045 93.4764C67.8974 97.199 43.4902 100.922 19.0831 104.641C17.6065 104.867 18.2343 107.128 19.7003 106.905Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M6.90927 49.9624C2.78821 50.9992 3.26871 54.5413 3.53527 58.1719C4.11046 66.0347 4.77334 73.8939 5.39412 81.7531C5.94827 88.7985 6.00439 96.1269 7.1162 103.105C7.7931 107.373 9.3328 110.766 13.7344 111.771C15.1935 112.104 15.8143 109.842 14.3517 109.506C7.87377 108.027 8.90491 96.7851 8.51911 91.8593C8.19644 87.7404 7.87026 83.625 7.54759 79.5061C7.26 75.8437 3.51422 53.2356 7.53356 52.2271C8.98558 51.8626 8.3718 49.5979 6.91628 49.9624H6.90927Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M2.27627 37.8707C0.266601 38.5784 -0.280534 40.3937 0.12631 42.4071C0.799708 45.7299 2.28329 49.0208 3.70724 52.0746C4.34206 53.4405 6.35173 52.2515 5.71691 50.8892C5.25395 49.8948 0.897912 40.836 2.89706 40.1318C4.30348 39.6364 3.69672 37.3682 2.27978 37.8671L2.27627 37.8707Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M15.9791 49.6405C38.2223 38.1294 61.2265 28.1506 83.0453 15.808C83.3715 15.624 83.6661 15.1852 83.6205 14.796C83.5328 14.0033 83.4451 13.2107 83.3575 12.418C83.2592 11.5121 82.4526 10.9849 81.6073 11.406C57.3895 23.4124 33.3366 35.755 9.12236 47.7686C7.77908 48.4338 8.95752 50.4614 10.2973 49.7962C34.5116 37.7826 58.5645 25.44 82.7823 13.4265C82.2001 13.0903 81.6143 12.7506 81.0321 12.4145C81.2776 14.6509 81.0321 14.4209 78.9979 15.3975C76.0413 16.82 73.2004 18.5752 70.2928 20.1038C64.113 23.3523 57.8595 26.4556 51.585 29.513C39.3025 35.5003 26.9429 41.3319 14.8042 47.6129C13.4714 48.3029 14.6463 50.3305 15.9791 49.6405Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M21.9767 46.4986C44.6828 43.1971 67.631 42.0187 90.3371 38.7172C91.8137 38.5014 91.1859 36.2402 89.7198 36.4525C67.0137 39.754 44.0656 40.9324 21.3594 44.2339C19.8829 44.4497 20.5107 46.7109 21.9767 46.4986Z"
          fill={color ? color : "#033447"}
        />
        <path
          d="M89.4391 38.5968C90.7509 39.209 92.0626 39.8212 93.3778 40.4298C94.7281 41.0597 95.9136 39.0356 94.5528 38.4022C93.241 37.79 91.9293 37.1779 90.6141 36.5692C89.2638 35.9394 88.0783 37.9634 89.4391 38.5968Z"
          fill={color ? color : "#033447"}
        />
      </g>
      <defs>
        <clipPath id="clip0_416_102367">
          <rect width="99" height="112" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SectionThreeSvgImg;

type Props = {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
};

const MagnifierSvgIcon = ({ color, width, height, margin }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0" }}
      width={width ? width : "17"}
      height={height ? height : "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.3125 6.9294C0.3125 7.8339 0.48109 8.6822 0.81827 9.47431C1.1608 10.2611 1.63178 10.9542 2.23122 11.5536C2.83065 12.153 3.52374 12.6213 4.31049 12.9585C5.1026 13.2957 5.94822 13.4643 6.84737 13.4643C7.51638 13.4643 8.15328 13.3679 8.75806 13.1753C9.36819 12.9772 9.93016 12.707 10.444 12.3644L14.1289 16.0493C14.2466 16.1671 14.3777 16.2527 14.5222 16.3062C14.6721 16.3651 14.8273 16.3945 14.9879 16.3945C15.218 16.3945 15.4214 16.341 15.598 16.234C15.7746 16.1323 15.9138 15.9904 16.0155 15.8085C16.1171 15.6265 16.168 15.4231 16.168 15.1983C16.168 15.0378 16.1386 14.8852 16.0797 14.7407C16.0262 14.5962 15.9432 14.4705 15.8308 14.3634L12.17 10.6866C12.55 10.1621 12.847 9.58135 13.0611 8.94446C13.2806 8.30756 13.3903 7.63588 13.3903 6.9294C13.3903 6.03026 13.219 5.18731 12.8765 4.40055C12.5393 3.60845 12.071 2.91268 11.4716 2.31325C10.8721 1.70846 10.1764 1.23748 9.38425 0.900301C8.5975 0.563121 7.75187 0.394531 6.84737 0.394531C5.94822 0.394531 5.1026 0.563121 4.31049 0.900301C3.52374 1.23748 2.83065 1.70846 2.23122 2.31325C1.63178 2.91268 1.1608 3.60845 0.81827 4.40055C0.48109 5.18731 0.3125 6.03026 0.3125 6.9294ZM2.02248 6.9294C2.02248 6.26575 2.14558 5.64223 2.39178 5.05886C2.64332 4.47548 2.99121 3.96168 3.43543 3.51746C3.87965 3.07324 4.39345 2.72803 4.97682 2.48184C5.5602 2.23029 6.18372 2.10452 6.84737 2.10452C7.51638 2.10452 8.14257 2.23029 8.72595 2.48184C9.30932 2.72803 9.82044 3.07324 10.2593 3.51746C10.7035 3.96168 11.0514 4.47548 11.303 5.05886C11.5545 5.64223 11.6803 6.26575 11.6803 6.9294C11.6803 7.59841 11.5545 8.2246 11.303 8.80798C11.0514 9.39135 10.7035 9.90515 10.2593 10.3494C9.82044 10.7882 9.30932 11.1335 8.72595 11.385C8.14257 11.6365 7.51638 11.7623 6.84737 11.7623C6.18372 11.7623 5.5602 11.6365 4.97682 11.385C4.39345 11.1335 3.87965 10.7882 3.43543 10.3494C2.99121 9.90515 2.64332 9.39135 2.39178 8.80798C2.14558 8.2246 2.02248 7.59841 2.02248 6.9294Z"
        fill={color ? color : "#454545"}
      />
    </svg>
  );
};

export default MagnifierSvgIcon;


// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
// @ts-nocheck
import DefaultLayout from "@Components/DefaultLayout";
import CommonSection from "@Components/LandingPage/CommonSection";
import CoursesSection from "@Components/LandingPage/CoursesSection";
import HeroArea from "@Components/LandingPage/HeroArea";
import { BoxWrapper } from "@Components/LandingPage/LandingPage.styled";
import PlatformsSection from "@Components/LandingPage/PlatformsSection";
import TeachersSection from "@Components/LandingPage/TeachersSection";
import {
  COOKIE_LANG,
  FOUNDING_DAY_THEME,
  KSA_COUNTRY_CODE,
  SECTION_ONE,
  SECTION_THREE,
  SECTION_TWO,
} from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { getOnBoardingCategories } from "@Services/Categories";
import { getLocalizedHomeSubRoute } from "@Utils/Strings";
import Box from "@mui/material/Box";
import { getCommonSectionBgColor } from "LocaleBased";
import { NextPage } from "next";
import { getPageSeo } from "next-seo.config";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useCookie } from "react-use";
import useScrollSpy from "react-use-scrollspy";
interface Props {}

const LandingPage: NextPage<Props> = ({}) => {
  const { locale, asPath } = useRouter();
  const { t, lang } = useTranslation("common");
  const { t: tAuth } = useTranslation("Auth");
  const { t: tLanding } = useTranslation("Landing");
  const [commonBGChanger, setCommonBG] = useState("white");
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [isFoundingDay, setIsFoundingDay] = useState<boolean>(
    Boolean(localIpInfo?.country_code === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME)
  );
  const [getLang] = useCookie(COOKIE_LANG);
  const {
    isLoading: isCountriesLoading,
    error,
    data: countriesData,
  } = useQuery("categories", () => getOnBoardingCategories().then(({ data: { data } }) => data));

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -350,
  });

  useEffect(() => {
    if (window.innerHeight > 2560) {
      setCommonBG("white");
    } else {
      activeSection < 3
        ? setCommonBG("white")
        : activeSection === 3
          ? setCommonBG(isFoundingDay ? "KsaFounding_yellowColor" : "yellow")
          : activeSection === 4
            ? setCommonBG(getCommonSectionBgColor(localIpInfo?.country_code, themeType))
            : setCommonBG("gray7");
    }
  }, [activeSection]);

  const [subRoute, setSubroute] = useState("");
  useEffect(() => {
    if (typeof window !== "undefined") {
      setSubroute(getLocalizedHomeSubRoute() || "");
    }
  }, []);

  return (
    <DefaultLayout
      seoData={{
        ...getPageSeo({
          titleTemplate: `${t("baims")} | %s `,
          title: `${t("meta_tag_title")}`,
          description: `${t("meta_tag_description")}`,
          locale,
          asPath,
        }),
      }}
      isLanding={true}
      bgColor={commonBGChanger}
      navbarType={
        !activeSection
          ? "default"
          : activeSection === 0
            ? "default"
            : activeSection === 3
              ? "seagull-bg"
              : activeSection === 4
                ? isFoundingDay
                  ? "white-bg"
                  : "seagull-bg"
                : [7].includes(activeSection)
                  ? "default"
                  : activeSection === 5
                    ? "white-bg"
                    : "white-bg"
      }
    >
      <Head>
        <meta itemProp="name" content={t("meta_tag_title")} />
        <meta itemProp="description" content={t("meta_tag_description")} />
        <meta
          itemProp="image"
          content={
            locale === "ar"
              ? "https://s3.eu-central-1.amazonaws.com/baims/uploads/1648030856_logo_baims_blue_ar.png"
              : "https://s3.eu-central-1.amazonaws.com/baims/uploads/1647877893_logo_baims_blue_en.png"
          }
        />
      </Head>
      <Box ref={sectionRefs[0]} style={{ height: "100vh" }}>
        <div style={{ visibility: activeSection > 1 ? "hidden" : "unset" }}>
          <HeroArea isCountriesLoading={isCountriesLoading} countriesData={countriesData} />
        </div>
      </Box>
      <BoxWrapper commonBG={commonBGChanger}>
        <Box ref={sectionRefs[1]} className={activeSection > 0 && "common-section-animate"}>
          <CommonSection
            icon={SECTION_ONE}
            title={
              <Trans
                i18nKey="Landing:landing_freedom_title"
                components={{
                  span: <span className="underlined" />,
                }}
              />
            }
            desc={tLanding("landing_freedom_body")}
            mediaURL={`/images/landing-common-sections/sec1_${locale}.mp4`}
            radius="top"
            cta={{
              text: tLanding("explore_courses"),
              action: `/home/${subRoute}`,
            }}
            titleClass="sec1__title"
          />
        </Box>
        <Box ref={sectionRefs[2]} className={activeSection > 1 && "common-section-animate"}>
          <CommonSection
            icon={SECTION_TWO}
            title={
              <Trans
                i18nKey="Landing:landing_courses_title"
                components={{
                  span: <span className="underlined" />,
                }}
              />
            }
            desc={tLanding("landing_courses_body")}
            mediaURL={`/images/landing-common-sections/sec2_${locale}.png`}
            cta={{
              text: tLanding("explore_courses"),
              action: `/home/${subRoute}`,
            }}
            titleClass="sec2__title"
            width={1400}
            height={1150}
          />
        </Box>
        <Box ref={sectionRefs[3]} className={activeSection >= 2 && "common-section-animate"}>
          <CommonSection
            icon={SECTION_THREE}
            title={
              <Trans
                i18nKey="Landing:landing_lives_title"
                components={{
                  span: <span className="underlined" />,
                }}
              />
            }
            desc={tLanding("landing_lives_body")}
            mediaURL={`/images/landing-common-sections/sec3_${locale}.png`}
            cta={{
              text: tLanding("explore_courses"),
              action: `/home/${subRoute}`,
              variant: "white-filled",
            }}
            titleClass="sec3__title"
            width={800}
            height={1500}
            arrowURL
          />
        </Box>
        <Box ref={sectionRefs[4]}>
          <TeachersSection />
        </Box>
        <Box ref={sectionRefs[5]}>
          <CoursesSection />
        </Box>
        <Box ref={sectionRefs[6]}>
          <PlatformsSection />
        </Box>
      </BoxWrapper>
    </DefaultLayout>
  );
};

 async function _getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default LandingPage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  
import { CommonButton } from "@Components/common/CommonButton";
import { useHeroContext } from "@Context/Hero.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import CollegeOrGradeSvgIcon from "public/svgs/icons/global-icons/CollegeSvgIcon";
import CountrySvgIcon from "public/svgs/icons/global-icons/CountrySvgIcon";
import LightArrowRightSvgIcon from "public/svgs/icons/global-icons/LightArrowRightSvgIcon";
import StudyStageSvgIcon from "public/svgs/icons/global-icons/StudyStageSvgIcon";
import {
  ArrowDirectionWrapper,
  CTAMobileContainer,
  CTAMobileHolder,
  CTAMobileLi,
  CTAMobileLiContent,
} from "../../HeroArea.styled";
import { useAuth } from "@Context/Auth.context";
import { ExploreEvent, filterCategoriesByType } from "@Utils/Analytics";
import { UserProfile } from "types/users";
import {
  COLLEGE_TYPE,
  COUNTRY_TYPE,
  DEPARTMENT_TYPE,
  GRADE_TYPE,
  LANDING_CTA,
  STAGE_TYPE,
  UNIVERSITY_TYPE,
} from "@Constants/Breadcrumb";
import { _GetBreadCrumbs } from "@Services/Home";
import { __explore } from "@repo/analytics";

interface Props {}

export default function CTAMobile({}: Props) {
  const router = useRouter();
  const { userData } = useAuth();
  const { t } = useTranslation("common");
  const { setIsCTAMobileLayer, currentSelectedStudyStage, CTAURL } = useHeroContext();
  const categoriesArray = CTAURL?.split("/");
  const lastCategorySlug = categoriesArray && categoriesArray[categoriesArray?.length - 1];

  const searchHandler = async (e: React.MouseEvent<Element>, catSlug: string) => {
    e.preventDefault();
    try {
      const response = await _GetBreadCrumbs(catSlug);
      __explore(
        {
          country_selected: filterCategoriesByType(response?.data, COUNTRY_TYPE),
          stage_selected: filterCategoriesByType(response?.data, STAGE_TYPE),
          university_selected: filterCategoriesByType(response?.data, UNIVERSITY_TYPE),
          college_selected: filterCategoriesByType(response?.data, COLLEGE_TYPE),
          grade_selected: filterCategoriesByType(response?.data, GRADE_TYPE),
          department_selected: filterCategoriesByType(response?.data, DEPARTMENT_TYPE),
          trigger_source: LANDING_CTA,
        },
        userData
      );
    } catch (error) {
      console.log("Error in explore analytics", error);
    }
    setTimeout(() => {
      router.push(CTAURL, undefined, {
        shallow: true,
        scroll: true,
      });
    }, 1100);
  };

  return (
    <CTAMobileHolder>
      <CTAMobileContainer>
        <CTAMobileLi variant="default" onClick={() => setIsCTAMobileLayer(true)}>
          <CTAMobileLiContent>
            <CountrySvgIcon margin="0 8px" />
            {t("country")}
          </CTAMobileLiContent>
          <ArrowDirectionWrapper>
            <LightArrowRightSvgIcon color="#1a1a1a" width="9" height="14" />
          </ArrowDirectionWrapper>
        </CTAMobileLi>
        <CTAMobileLi variant="default" onClick={() => setIsCTAMobileLayer(true)}>
          <CTAMobileLiContent>
            <StudyStageSvgIcon margin="0 8px" />
            {t("study-stage")}
          </CTAMobileLiContent>
          <ArrowDirectionWrapper>
            <LightArrowRightSvgIcon color="#1a1a1a" width="9" height="14" />
          </ArrowDirectionWrapper>
        </CTAMobileLi>
        <CTAMobileLi variant="default" onClick={() => setIsCTAMobileLayer(true)}>
          <CTAMobileLiContent>
            <CollegeOrGradeSvgIcon margin="0 8px" />
            {t("college-or-grade")}
          </CTAMobileLiContent>
          <ArrowDirectionWrapper>
            <LightArrowRightSvgIcon color="#1a1a1a" width="9" height="14" />
          </ArrowDirectionWrapper>
        </CTAMobileLi>
        <CTAMobileLi variant="empty-bg">
          <CommonButton
            onClick={async (e) => {
              if (currentSelectedStudyStage.name && CTAURL) {
                await searchHandler(e, lastCategorySlug);
              } else {
                setIsCTAMobileLayer(true);
              }
            }}
            variant="default"
          >
            {t("search")}
          </CommonButton>
        </CTAMobileLi>
      </CTAMobileContainer>
    </CTAMobileHolder>
  );
}

import React, { Fragment, ReactElement } from "react";
import CTADesktop from "../CTADesktop";
import CTAMobile from "../CTAMobile";

interface Props {}

export default function CTA({}: Props): ReactElement {
  return (
    <Fragment>
      <CTADesktop />
      <CTAMobile />
    </Fragment>
  );
}

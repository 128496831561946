import HeroAreaProvider from "@Context/Hero.context";
import VideoComponent from "LocaleBased/VideoComponent";
import useTranslation from "next-translate/useTranslation";
import { ReactElement, useEffect, useRef } from "react";
import { isAndroid } from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { CategoriesType } from "types/categories";
import {
  HeadLine,
  HeroAreaContainer,
  InfoContainer,
  InfoContentContainer,
  Overlay,
  Paragraph,
} from "./HeroArea.styled";
import CTA from "./components/CTA";
import CTAMobileLayerHandler from "./components/CTAMobileLayerHandler";

interface Props {
  isCountriesLoading: Boolean;
  countriesData: CategoriesType;
}

export default function HeroArea({ countriesData, isCountriesLoading }: Props): ReactElement {
  const { t: tLanding } = useTranslation("Landing");
  const scroller = useRef<HTMLDivElement>();
  const { isBannerVisible } = useSelector((state: RootState) => state.banner);

  useEffect(() => {
    if (typeof window !== "undefined" && isAndroid && scroller && scroller.current) {
      const scrollTheFullPageSection = () => {
        window.fullpage_api.moveSectionDown();
      };
      scroller.current.addEventListener("swiped-up", scrollTheFullPageSection);
      return () => {
        scroller.current.removeEventListener("swiped-up", scrollTheFullPageSection);
      };
    }
  }, []);

  return (
    <HeroAreaProvider countriesData={countriesData} isCountriesLoading={isCountriesLoading}>
      <HeroAreaContainer id="main_heroArea" ref={scroller}>
        <VideoComponent />
        <Overlay />
        <CTAMobileLayerHandler />
        <InfoContentContainer isBannerVisible={isBannerVisible}>
          <InfoContainer>
            <HeadLine>{tLanding("landing_header_title")}</HeadLine>
            <Paragraph isBannerVisible={isBannerVisible}>{tLanding("landing_header_body")}</Paragraph>
            <CTA />
          </InfoContainer>
        </InfoContentContainer>
      </HeroAreaContainer>
    </HeroAreaProvider>
  );
}

import Axios from "lib/Axios";
import { APIResponse } from "types/ApiResponse";
import { Sessions } from "types/homepage";
import { InstructorReview } from "types/InstructorReviews";
import { Instructor, ProfileFields } from "types/Instructors";
import { Package } from "types/Package";
import { Subject } from "types/subject";

export const _GetTopInstructors = (country: string) =>
  Axios.get<APIResponse<Instructor[], {}>>(`/v1/top-instructors?country=${country}`);

export const _GetInstructorProfile = (name: string, token?: string) =>
  Axios.get<APIResponse<Instructor, {}>>(`/v1/instructors/${name}`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then((res) => res.data);

export const _GetInstructorSubjects = ({
  name,
  token,
  params,
}: {
  name: string;
  token?: string;
  params?: {
    limit?: number;
  };
}) =>
  Axios.get<APIResponse<Subject[], {}>>(`/v1/instructors/${name}/subjects`, {
    params,
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then((res) => res.data);

export const _GetInstructorReviews = ({
  name,
  token,
  params,
}: {
  name: string;
  token?: string;
  params?: {
    limit?: number;
  };
}) =>
  Axios.get<APIResponse<InstructorReview[], {}>>(`/v1/instructors/${name}/reviews`, {
    params,
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then((res) => res.data);

export const _GetInstructorLiveSessions = ({
  name,
  token,
  params,
}: {
  name: string | number;
  token?: string;
  params?: {
    limit?: number;
  };
}) =>
  Axios.get<APIResponse<Sessions[], {}>>(`/v1/instructors/${name}/live-sessions`, {
    params,
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then((res) => res.data);

export const _GetInstructorPackages = ({
  name,
  token,
  params,
}: {
  name: string;
  token?: string;
  params?: {
    limit?: number;
  };
}) =>
  Axios.get<APIResponse<Package[], {}>>(`/v1/instructors/${name}/packages`, {
    params,
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}),
  }).then((res) => res.data);

export const _UpdateInstructorProfile = (data: ProfileFields) =>
  Axios.put<APIResponse<{ message: string }, {}>>(`/v1/profile`, data);

export const _UpdateInstructorImage = (data: FormData) =>
  Axios.post<APIResponse<{ message: string }, {}>>(`/v1/profile/media`, data);

import ContentLoader from "react-content-loader";

export default function TeacherCardSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="80px" backgroundColor="#E3F2FD" foregroundColor="#c4c4c4">
      <rect x="0" y="12" rx="50" ry="50" width="52" height="52" />
      <rect x="60" y="20" rx="4" ry="4" width="150" height="10" />
      <rect x="60" y="45" rx="4" ry="4" width="130" height="10" />
    </ContentLoader>
  );
}

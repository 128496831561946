import { COUNTRY } from "@Constants/Breadcrumb";
import { CategoriesType } from "types/categories";

const addnewObjToParentChildren = ({ parent, newObj }: { parent: CategoriesType; newObj: CategoriesType }) => {
  parent.children = [newObj];
};

// Check if items[identifier] has newObj.id as children
const isIncludeObjectAsChildren = ({
  items,
  newObj,
  identifier,
}: {
  items: CategoriesType[];
  newObj: CategoriesType;
  identifier: number;
}) =>
  items[identifier] &&
  items[identifier].has_children &&
  items[identifier].children?.find((x: CategoriesType) => x.id === newObj.id);

// Handlre breadcrumb arr, return => Array of objects based on changes
// @items: Array want to change, @newObj: Additional object want to add it
export const handleBreadcrumbARR = ({ items, newObj }: { items: CategoriesType[]; newObj: CategoriesType }) => {
  let parentOfnewObj = 0;
  const last = items.length - 1;

  // GET parent of new obj IF found
  for (let i = 0; i <= items.length; i++) {
    if (
      isIncludeObjectAsChildren({
        items,
        newObj,
        identifier: i,
      })
    )
      parentOfnewObj = i;
  }

  // Check if parent + 1 element is not equal current new obj
  if (items[parentOfnewObj + 1] && newObj.id !== items[parentOfnewObj + 1].id) {
    items[parentOfnewObj + 1] = newObj;
    // Remove rest of array if item has changed
    items.splice(parentOfnewObj + 2, last);
  }

  // if type is country set new array
  if (newObj.type === COUNTRY) {
    items = [newObj];
  } else {
    // if last element contain children & id of new Obj, add it normally
    if (
      isIncludeObjectAsChildren({
        items,
        newObj,
        identifier: last,
      })
    )
      items.push(newObj);
  }

  return items;
};

export const handleNestedChildren = ({
  items,
  childrens,
  newObj,
  step,
}: {
  items: CategoriesType[];
  childrens: any;
  newObj: CategoriesType;
  step: string;
}) => {
  if (step === COUNTRY) {
    items = [{ ...newObj, children: childrens, type: step }];
  } else if (childrens.length) {
    items = handleBreadcrumbARR({
      items: items,
      newObj: { ...newObj, children: childrens, type: step },
    });
  } else {
    items = handleBreadcrumbARR({
      items: items,
      newObj: { ...newObj, children: [], type: step },
    });
  }
  return items;
};

// Convert slugs to countries
export const slugToCountry = (slug) => {
  const isSlug = (country) => slug == country;
  let currentCountry = "";

  if (isSlug("kw")) {
    currentCountry = "";
  } else if (isSlug("tr")) {
    currentCountry = "turkey";
  } else if (isSlug("sa")) {
    currentCountry = "ksa";
  } else if (isSlug("bh")) {
    currentCountry = "bahrain";
  } else if (isSlug("jo")) {
    currentCountry = "jordan";
  }

  return currentCountry;
};

import Navbar from "@Components/Navbar";
import { useAuth } from "@Context/Auth.context";
import { DefaultSeoProps, NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { ReactNode } from "react";
import useResizeAware from "react-resize-aware";
import { NavbarVariant } from "types/common";
import { PageWrapper, Wrapper } from "./DefaultLayout.styled";

const Footer = dynamic(() => import("@Components/Footer"), {
  ssr: false,
});

export default function DefaultLayout({
  children,
  seoData,
  noFollow,
  hideFooter,
  navbarType,
  baseFooter,
  bgColor,
  isLanding,
}: {
  children: ReactNode;
  seoData: DefaultSeoProps;
  noFollow?: boolean;
  hideFooter?: boolean;
  navbarType?: NavbarVariant;
  baseFooter?: boolean;
  bgColor?: string;
  isLanding?: boolean;
}) {
  const [resizeListener, { height }] = useResizeAware();
  const { localIpInfo } = useAuth();

  return (
    <PageWrapper
      style={{
        paddingBottom: height,
      }}
      bgColor={bgColor || "white"}
    >
      <NextSeo {...seoData} {...(noFollow && { nofollow: true, noindex: true })} />
      <Navbar isLanding={isLanding} navbarType={navbarType || "default"} />
      <Wrapper>{children}</Wrapper>
      {!hideFooter && (
        // @ts-ignore
        <Footer
          isLanding={isLanding}
          resizeListener={resizeListener}
          baseFooter={baseFooter}
          countryCode={localIpInfo?.country_code}
        />
      )}
    </PageWrapper>
  );
}

import { CommonButton } from "@Components/common/CommonButton";
import { mq, mw } from "@Utils/StyleUtil";
import styled, { css } from "styled-components";

export const GetStartedWrapper = styled.div`
  margin-bottom: 40px;
`;

export const SectionTitle = styled.h2<{
  readonly isFoundingDay?: boolean;
}>`
  font-size: 3.6rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.5;
  ${mw(1920)} {
    font-size: calc(3.6rem * 1.2);
  }
  .circle {
    display: inline-block;
    width: 136px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      ${({ isFoundingDay }) =>
        isFoundingDay
          ? css`
              background-image: url("/images/landing-common-sections/ksaFounding/circle_brown.svg");
            `
          : css`
              background-image: url("/images/landing-common-sections/circle.svg");
            `}
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    ${mw(1920)} {
      width: calc(136px * 1.2);
    }
  }
  .underlined {
    ${({ isFoundingDay }) =>
      isFoundingDay
        ? css`
            background: url("/images/landing-common-sections/ksaFounding/footer_underline_brown.svg") no-repeat;
          `
        : css`
            background: url("/images/landing-common-sections/footer_underline_seagule.svg") no-repeat;
          `}
    background-position: center bottom;
    background-size: 100% auto;
    /* padding-bottom: 4px; */
    white-space: nowrap;
  }
  ${mq(992)} {
    font-size: 2.8rem;
    margin-bottom: 35px;
    .circle {
      width: 104px;
    }
  }
  ${mq(768)} {
    width: 100%;
    align-items: center;
    font-size: 2.4rem;
    margin-bottom: 30px;
  }
  ${mq(576)} {
    .circle {
      width: 86px;
    }
  }
`;
export const SectionDesc = styled.p`
  text-align: center;
  font-size: 2rem;
  line-height: 1.6;
  max-width: 560px;
  margin: 0 auto 40px;
  ${mw(1920)} {
    font-size: calc(2rem * 1.2);
  }
  ${mq(992)} {
    font-size: 1.8rem;
    margin: 0 auto 40px;
  }
  ${mq(768)} {
    font-size: 1.6rem;
    margin: 0 auto 32px;
    max-width: 272px;
  }
`;
export const SectionActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  ${mq(768)} {
    flex-direction: column;
  }
`;
export const SectionActionBtn = styled(CommonButton)`
  min-width: 272px;
  height: 48px;
`;

type Props = {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
};

const CloseSvgIcon = ({ color, width, height, margin }: Props) => {
  return (
    <svg
      style={{ margin: margin ? margin : "0" }}
      width={width ? width : "14"}
      height={height ? height : "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.01385 12.9893C1.1076 13.0778 1.21437 13.1377 1.33416 13.1689C1.45395 13.2002 1.57375 13.2002 1.69354 13.1689C1.81333 13.1377 1.9175 13.0778 2.00604 12.9893L7.00604 7.98926L12.006 12.9893C12.0946 13.0778 12.1987 13.1377 12.3185 13.1689C12.4383 13.2002 12.5581 13.2002 12.6779 13.1689C12.8029 13.1429 12.9097 13.083 12.9982 12.9893C13.0868 12.9007 13.1441 12.7965 13.1701 12.6768C13.2013 12.557 13.2013 12.4372 13.1701 12.3174C13.1441 12.1976 13.0868 12.0934 12.9982 12.0049L7.99822 6.99707L12.9982 1.99707C13.0868 1.90853 13.1467 1.80436 13.1779 1.68457C13.2092 1.56478 13.2092 1.44499 13.1779 1.3252C13.1467 1.2054 13.0868 1.10124 12.9982 1.0127C12.9045 0.918945 12.7977 0.859049 12.6779 0.833008C12.5581 0.801758 12.4383 0.801758 12.3185 0.833008C12.1987 0.859049 12.0946 0.918945 12.006 1.0127L7.00604 6.0127L2.00604 1.0127C1.9175 0.918945 1.81072 0.859049 1.68572 0.833008C1.56593 0.801758 1.44614 0.801758 1.32635 0.833008C1.20656 0.859049 1.10239 0.918945 1.01385 1.0127C0.925308 1.10124 0.865412 1.2054 0.834162 1.3252C0.80812 1.44499 0.80812 1.56478 0.834162 1.68457C0.865412 1.80436 0.925308 1.90853 1.01385 1.99707L6.01385 6.99707L1.01385 12.0049C0.925308 12.0934 0.865412 12.1976 0.834162 12.3174C0.802912 12.4372 0.800308 12.557 0.826349 12.6768C0.857599 12.7965 0.920099 12.9007 1.01385 12.9893Z"
        fill={color ? color : "#ABABAB"}
      />
    </svg>
  );
};

export default CloseSvgIcon;

import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE } from "@Constants/index";
import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { _GetTopInstructors } from "@Services/Instructors";
import chunk from "lodash/chunk";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { ReactElement, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useMedia } from "react-use";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { Instructor } from "types/Instructors";
import { LandingBGColors } from "types/common";
import {
  InstructorCardSpacer,
  MarqueeSpacer,
  OuterWrapper,
  SectionDesc,
  SectionTitle,
  Wrapper,
} from "./TeachersSection.styled";
import TeacherCard from "./components/TeacherCard";

interface Props {
  commonBG?: LandingBGColors;
}

export default function TeachersSection({ commonBG }: Props): ReactElement {
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const [isFoundingDay, setIsFoundingDay] = useState<boolean>();
  const { t: tLanding } = useTranslation("Landing");
  const [topInstructors, setTopInstructors] = useState<Instructor[][]>();
  const isTablet = useMedia("(max-width: 991.98px)");

  useEffect(() => {
    // TODO: Needs to be tested (LOCALE_IP_BASED_TEST)
    _GetTopInstructors(localIpInfo?.country === "saudi arabia" ? "saudi" : localIpInfo?.country).then(({ data }) => {
      const chunks = chunk(
        data.data,
        Math.round(localIpInfo?.country === "bahrain" ? data.data.length / 1 : data.data.length / 3)
      );
      setTopInstructors(chunks);
      setIsFoundingDay(Boolean(localIpInfo?.country_code === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME));
    });
  }, []);

  return (
    <OuterWrapper commonBG={commonBG}>
      <Wrapper>
        <Container fluid={isTablet}>
          <Row>
            <Col col={12}>
              <SectionTitle isFoundingDay={isFoundingDay}>
                <Trans
                  i18nKey="Landing:landing_teachers_title"
                  components={{
                    span: <span className="underlined" />,
                    p: <span className="heart" />,
                  }}
                />
              </SectionTitle>
              <SectionDesc>{tLanding("landing_teachers_body")}</SectionDesc>
            </Col>
          </Row>
        </Container>
        <div>
          {topInstructors
            ? topInstructors.map((chunk, i) => (
                <MarqueeSpacer key={"INSTRUCTOR_CHUNK_" + i} dir="ltr">
                  <Marquee
                    pauseOnHover
                    gradient={false}
                    speed={50}
                    direction={i % 2 === 0 ? "left" : "right"}
                    delay={i / 2}
                  >
                    {chunk.map((ins) => (
                      <InstructorCardSpacer key={ins.id + ins.username}>
                        <TeacherCard data={ins} />
                      </InstructorCardSpacer>
                    ))}
                  </Marquee>
                </MarqueeSpacer>
              ))
            : [...new Array(3)].map((_, i) => (
                <MarqueeSpacer key={"INSTRUCTOR_CHUNK_DUMMY_" + i} dir="ltr">
                  <Marquee
                    pauseOnHover
                    gradient={false}
                    speed={50}
                    direction={i % 2 === 0 ? "left" : "right"}
                    delay={i / 2}
                  >
                    {[...new Array(5)].map((_, i) => (
                      <InstructorCardSpacer key={i + "_DUMMY"}>
                        <TeacherCard />
                      </InstructorCardSpacer>
                    ))}
                  </Marquee>
                </MarqueeSpacer>
              ))}
        </div>
        {/* Hide Button till we need it */}
        {/* <Container>
          <Row justifyContent="center">
            <Col auto>
              <TeachersExploreBtn variant="default">
                See all teachers
                <ExploreBtnIcon src="/images/icons/arrow-right-white.svg" alt="Arrow" />
              </TeachersExploreBtn>
            </Col>
          </Row>
        </Container> */}
      </Wrapper>
    </OuterWrapper>
  );
}
